import React, { Dispatch, SetStateAction } from "react";
import { useDimensions, breakpoints } from "../../core/hooks/dimensionProvider";
import {
  BaseColumnsProps,
  BaseFiltersResponse,
  FilterData,
} from "../../core/api/common_table";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import { Switch } from "../../ui-lib/components/Switch/Switch";
import { toSafeInteger } from "lodash";
import useUser from "../../core/user/useUser";
import TableSearch, {
  SearchFilter,
} from "../../components/FilterSearch/TableSearch";
import {
  ABSENCE_ON_OBJECT,
  CustomFilters,
  LINKED_OBJECT_ID,
} from "../../ui-lib/components/Tables/CustomFilters";
import useTranslations from "../../core/i18n/useTranslations";
import { Role, RoleFeature } from "../../components/RoleFeature";
import { ExactMatchTogller } from "../../components/ExactMatchTogller";
import Divider from "../../ui-lib/components/Divider/Divider";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import DateFilterDropdown from "../../components/DateFilter/DateFilterDropdown";
import { ClearFilter } from "../../ui-lib/components/Tables/CleatFilters";
import TableDataControlButton from "../../ui-lib/components/Tables/TableDataControlButton";

import styles from "../Layout.module.css";
import PageStateStore from "../../core/pagestate/PagestateStore";
import { CustomersFilter } from "../../core/hooks/filters/useCustomersFilter";
import { OrganisationFilter } from "../../core/hooks/filters/useOrganisationFilter";
import { DateFilter } from "../../core/hooks/filters/useDateFilter";

export const ObjectFilters = ({
  searchFilter,
  setSearchFilter,
  objectsFilters,
  availableFilters,
  resetTablePage,
  pageState,
  customFilters,
  setCustomFilters,
  setCustomersFilter,
  resetCustomersFilter,
  customersFilter,
  treeData,
  organisationFilter,
  dateType,
  setDateType,
  dateFilter,
  setDateFilter,
  searchState,
  onClear,
  allObjectsColumns,
  selectedColumns,
  onSelectedColumnChange,
  showTableControlButton,
}: {
  searchFilter: SearchFilter;
  setSearchFilter: (args: SearchFilter) => void;
  objectsFilters: BaseFiltersResponse;
  availableFilters: {
    amount: number;
    available: { id: number; keyName: string; name: string }[];
    notAvailable: FilterData[];
  };
  resetTablePage: () => void;
  pageState: PageStateStore;
  customFilters?: FilterData[];
  setCustomersFilter: (args: CustomersFilter) => void;
  setCustomFilters: Dispatch<SetStateAction<FilterData[] | undefined>>;
  resetCustomersFilter: () => void;
  customersFilter: CustomersFilter;
  treeData: ITreeData[];
  organisationFilter?: OrganisationFilter;
  dateType?: number;
  setDateType: Dispatch<SetStateAction<number | undefined>>;
  dateFilter: DateFilter;
  setDateFilter: (args: DateFilter) => void;
  searchState: any;
  onClear: () => void;
  allObjectsColumns: BaseColumnsProps;
  selectedColumns?: BaseColumnsProps;
  onSelectedColumnChange: (columns: BaseColumnsProps) => Promise<void>;
  showTableControlButton: boolean;
}) => {
  const t = useTranslations();
  const { width } = useDimensions();
  const { config } = useUser();

  const viserPermission = config?.show.includes("SensioHideFields");
  const isMobile = width < breakpoints.desktop;
  const tabletToDesktop =
    width > breakpoints.tablet && width < breakpoints.desktop;
  const dateFilterWidthAligner = tabletToDesktop ? "294px" : "56px";

  const specialFilters = [
    {
      id: 110,
      keyName: "UserQueryFilterPostalCode",
      name: "PostalCode",
    },
    {
      id: 109,
      keyName: "UserQueryFilterAddressStreet",
      name: "Street",
    },
  ];

  const filterTabs = [
    {
      id: 0,
      isSelected: ![109, 110].includes(toSafeInteger(searchFilter.id)),
      name: "Normal",
    },
    {
      id: 1,
      isSelected: [109, 110].includes(toSafeInteger(searchFilter.id)),
      name: "Special",
    },
  ];

  const tableSearchComponent = (
    <TableSearch
      placeholder={
        searchFilter?.id !== -1
          ? t("Objects:search_placeholder_generic")
          : t("Objects:search_placeholder")
      }
      dropdownPlaceholder={t("Objects:dropdown_search_placeholder")}
      searchFilter={searchFilter}
      setSearchFilter={setSearchFilter}
      types={([109, 110].includes(toSafeInteger(searchFilter.id))
        ? specialFilters
        : objectsFilters?.filter(
            (i) => i.id !== LINKED_OBJECT_ID && i.id !== ABSENCE_ON_OBJECT
          )
      ) // Remove linked object filter in this dropdown
        ?.map((i) => ({
          id: i.id,
          name: t(`Filters:${i.name}`),
          disabled: !!availableFilters.notAvailable.find(
            (el) => el.id === i.id
          ),
        }))}
      disabled={!searchFilter && !availableFilters.amount}
      resetPageFilter={resetTablePage}
      withDefaultSearch={!pageState.isSpecialSearch}
    />
  );

  const treeComponent = (
    <Tree
      withCheckboxTootip
      label={t("Common:select_organisation")}
      placeholder={`--${t("Common:organisation")}--`}
      hideLabel
      className={styles.treeFilter}
      disabled={!organisationFilter && !availableFilters.amount}
      items={treeData}
      selectedTreeItem={customersFilter.customers}
      onSelectItem={(ids: string) => {
        if (!ids) {
          resetCustomersFilter();
        } else {
          setCustomersFilter({ customers: ids });
        }
        // Do not reset page filters if value same as previous7
        if (customersFilter.customers !== ids) resetTablePage();
      }}
      selectionMode="multiple"
      showClearOption={false}
      style={isMobile ? { width: "100%" } : undefined}
    />
  );

  const dateFilterComponent = !viserPermission ? (
    <>
      <DateFilterDropdown
        width={isMobile ? `calc(100vw - ${dateFilterWidthAligner})` : undefined}
        placeholder={`--${t("Objects:date_filter_placeholder")}--`}
        dateType={dateType}
        setDateType={setDateType}
        dateFilter={dateFilter}
        setDateFilter={(dateData) => {
          setDateFilter(dateData);
          // Do not reset page filters if value same as previous
          if (
            dateData.start?.getDate() !== dateFilter.start?.getDate() &&
            dateData.end?.getDate() !== dateFilter.end?.getDate()
          ) {
            resetTablePage();
          }
        }}
        searchState={searchState}
      />
      <Spacer size={8} />
    </>
  ) : null;

  const clearFilterComponent = (
    <ClearFilter
      text={t("Common:labels_clear_all_filters")}
      onClearClick={onClear}
      filtersToWatch={[
        customersFilter,
        searchFilter,
        dateFilter,
        customFilters,
      ]}
      propertiesToExclude={{
        id: searchFilter.id === 110 ? 110 : -1,
        exact: searchFilter.exact === "true" ? undefined : "false",
      }}
    />
  );

  const filterColumns = (obj: BaseColumnsProps) => {
    if (!obj) return;
    const filteredColumns: { [key: number]: string } = {};
    for (const [key, value] of Object.entries(obj.columns)) {
      if (value === "Name" || value === "AlarmHandling") {
        filteredColumns[Number(key)] = value;
      }
    }
    return filteredColumns;
  };

  const renderMobileFilters = () => (
    <Column align="start" type="fill">
      {config?.show.includes("ObjectsSpecialSearch") && (
        <>
          <Switch
            tabs={filterTabs}
            onTabChange={(id: number) => {
              pageState.setIsSpecialSearch(!!id);
              onClear();
              setSearchFilter({
                id: pageState.isSpecialSearch ? 110 : -1,
                value: "",
              });
            }}
          />
          <Spacer size={16} />
        </>
      )}
      <Row type="fill" align="fill" style={{ flex: 0 }}>
        {tableSearchComponent}
      </Row>
      <RoleFeature requires={[Role.BetaAccess]}>
        <Spacer size={16} />
        <Row type="fill" align="fill" style={{ flex: 0 }}>
          <ExactMatchTogller
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
          />
        </Row>
      </RoleFeature>
      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      {treeComponent}

      <Spacer size={16} />

      {dateFilterComponent}

      <Spacer size={16} />
      {clearFilterComponent}
    </Column>
  );

  const renderDesktopFilters = () => (
    <>
      <Row className={styles.settingsRow}>
        {tableSearchComponent}
        <RoleFeature requires={[Role.BetaAccess]}>
          <Spacer size={16} />
          <ExactMatchTogller
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
          />
        </RoleFeature>

        {config?.show.includes("ObjectsSpecialSearch") && (
          <Switch
            tabs={filterTabs}
            onTabChange={(id: number) => {
              pageState.setIsSpecialSearch(!!id);
              onClear();
              setSearchFilter({
                id: pageState.isSpecialSearch ? 110 : -1,
                value: "",
              });
            }}
          />
        )}
      </Row>
      <Spacer size={16} />
      <Divider />
      <Spacer size={16} />
      <Row className={styles.settingsRow}>
        {treeData.length > 0 ? (
          <>
            {treeComponent}
            <Spacer size={8} />
          </>
        ) : null}
        {dateFilterComponent}
        {objectsFilters && (
          <CustomFilters
            filterColumns={objectsFilters.filter(
              (el) => el.id !== 3 && el.id !== 4 // should be removed from the backend side
            )}
            notAvailableFilters={availableFilters.notAvailable}
            filters={customFilters}
            maxFiltersAmount={availableFilters.amount}
            applyFilters={setCustomFilters}
            translate={(col) => t(`Filters:${col.name}`)}
            tableState={searchState}
            position="bottom left"
            resetPageFilter={resetTablePage}
            disableDropdowns={pageState.isSpecialSearch}
          />
        )}
        <Spacer size={8} />
        {clearFilterComponent}
        {showTableControlButton && (
          <TableDataControlButton
            allTableColumns={
              !viserPermission
                ? allObjectsColumns
                : ({
                    columns: filterColumns(allObjectsColumns),
                  } as BaseColumnsProps)
            }
            selectedTableColumns={selectedColumns}
            onVisibleColumnsChange={onSelectedColumnChange}
          />
        )}
      </Row>
    </>
  );

  return isMobile ? renderMobileFilters() : renderDesktopFilters();
};

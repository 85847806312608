import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useDateFilter } from "../../core/hooks/filters/useDateFilter";
import useTranslations from "../../core/i18n/useTranslations";
import DateTimeRangePickerPopup from "../../ui-lib/components/DateAndTime/DateTimeRangePickerPopup";
import Dropdown, {
  DropdownActions,
} from "../../ui-lib/components/Dropdown/Dropdown";
import styles from "./DateFilter.module.css";
import { getTimeSpan, getTimeSpanType } from "./timeSpan";
import { TimeSpan, TimeSpanType } from "./types";
import { OverlayPanel } from "primereact/overlaypanel";

const timeSpans: { type: TimeSpanType; key: string }[] = [
  {
    type: TimeSpanType.Today,
    key: "today",
  },
  {
    type: TimeSpanType.Last7Days,
    key: "last7days",
  },
  {
    type: TimeSpanType.Last10Days,
    key: "last10days",
  },
  {
    type: TimeSpanType.Last30Days,
    key: "last30days",
  },
  {
    type: TimeSpanType.Custom,
    key: "custom",
  },
];

const useDateDropdownFilter = ({
  searchState,
  setInitialDateType,
}: {
  searchState?: any;
  setInitialDateType?: number;
}) => {
  const { dateFilter, setDateFilter, resetDateFilter } =
    useDateFilter(searchState);

  let initialDateType: number | undefined = -1;
  if (searchState?.start && searchState?.end) {
    initialDateType = TimeSpanType.Custom;
  } else if (searchState?.start) {
    initialDateType = getTimeSpanType(searchState.start);
  }
  if (!searchState || setInitialDateType) {
    initialDateType = setInitialDateType;
  }

  const [dateType, setDateType] = useState<number | undefined>(initialDateType);

  const resetDateSettings = useCallback(() => {
    resetDateFilter();
    setDateType(undefined);
  }, [resetDateFilter, setDateType]);

  return {
    dateType,
    setDateType,
    dateFilter,
    setDateFilter,
    resetDateSettings,
  };
};

const DateFilterDropdown = ({
  placeholder,
  dateType,
  setDateType,
  dateFilter,
  setDateFilter,
  hideClearItem,
  setDefaultEndTime = false,
  searchState,
  width,
}: {
  placeholder: string;
  setDateType: (args?: TimeSpanType) => void;
  dateFilter: TimeSpan;
  setDateFilter: (args: TimeSpan) => void;
  dateType?: TimeSpanType;
  hideClearItem?: boolean;
  setDefaultEndTime?: boolean;
  searchState?: any;
  width?: number | string;
}) => {
  const t = useTranslations();
  const popupRef = useRef<OverlayPanel>(null);
  const dropdownRef = useRef<DropdownActions>(null);

  const [prevDateType, setPrevDateType] = useState(dateType);
  const [initialRange, setInitialRange] = useState<Date[] | undefined>(
    undefined
  );

  // Entity for clear date value after click clear all filters
  const renderFlag = searchState ? Object.keys(searchState).length : 0;

  useEffect(() => {
    if (dateType !== undefined && dateType !== 7 && dateType !== -1) {
      const { start, end } = getTimeSpan({ type: dateType, setDefaultEndTime });
      setDateFilter({ start, end });
    } else if (dateType !== undefined && dateType === 7) {
      setDateType(7);
      setDateFilter({
        start: moment(searchState.start).toDate(),
        end: moment(searchState.end).toDate(),
      });
    } else {
      setDateType(undefined);
    }
    if (dateFilter?.start) {
      const endRange = moment(dateFilter.start).toDate();
      endRange.setHours(23, 59, 59, 0);

      setInitialRange([dateFilter.start, dateFilter.end ?? endRange]);
    } else {
      setInitialRange(undefined);
    }
  }, [renderFlag]);

  const clearItem = {
    id: -1,
    name: `${placeholder}`,
    isSelected: dateType === -1 || dateType === undefined,
  };

  const items = timeSpans.map((ts) => ({
    id: ts.type,
    name: t(`Filters:${ts.key}`),
    isSelected: ts.type === dateType,
  }));

  const selectedItem = items.find((i) => i.isSelected);

  useEffect(() => {
    if (!dateFilter.start) {
      popupRef.current?.hide();
    }
  }, [dateFilter.start]);

  return (
    <div className={styles.dropdownWrapper}>
      <Dropdown
        id="dateFilterDropdown"
        ref={dropdownRef}
        items={hideClearItem ? [...items] : [clearItem, ...items]}
        selectedItem={selectedItem}
        placeholder={placeholder}
        width={width ?? 205}
        maxVisible={6}
        dataCy="date_filter_dropdown"
        onSelectItem={(item: any, e: any) => {
          if (item.id === clearItem.id) {
            setDateType(undefined);
            setDateFilter({});

            return;
          }

          const type = item.id as TimeSpanType;
          if (type === 7) {
            setPrevDateType(dateType);
          }
          setDateType(type);

          if (type === TimeSpanType.Custom) {
            popupRef.current?.show(
              e,
              document.querySelector("#dateFilterDropdown")
            );
          } else {
            const { start, end } = getTimeSpan({
              type,
              setDefaultEndTime,
            });
            setDateFilter({ start, end });
          }
        }}
        onOpenStateChanged={(isOpen) => {
          if (isOpen) {
            popupRef?.current?.hide();
          }
        }}
        withoutCheckIcon={false}
      />
      <DateTimeRangePickerPopup
        initialRange={initialRange}
        popupRef={popupRef}
        onPresetClick={() => dropdownRef.current?.open()}
        onSubmit={(range) => {
          if (range) {
            setInitialRange(range);
            setDateFilter({ start: range[0], end: range[1] });
          }
        }}
        setDateType={setDateType}
        prevDateType={prevDateType}
      />
    </div>
  );
};

export default DateFilterDropdown;
export { useDateDropdownFilter };

import PrimeTooltip from "../PrimeTooltip/PrimeTooltip";
import classnames from "classnames";
import styles from "./Table.module.css";
import Icon from "../Icon";
import PopupContextMenu from "../Menus/PopupContextMenu/PopupContextMenu";
import { PopupContextMenuLinkButton } from "../Menus/PopupContextMenu/PopupContextMenuLinkButton";
import { RowAction } from "./Table";

export const ActionColumnBody = <TRowData,>({
  rowIndex = 0,
  rowData,
  showTooltip,
  showRowsActionsOnHover,
  rowActionContextMenu,
  isMobile,
  rowActions,
}: {
  rowIndex?: number;
  rowData: TRowData;
  showTooltip: boolean;
  showRowsActionsOnHover: boolean;
  rowActionContextMenu?: boolean;
  isMobile: boolean;
  rowActions: RowAction<TRowData>[];
}) => {
  const tooltipText = (action: RowAction<TRowData>) =>
    typeof action.text === "string" ? action.text : action?.text?.(rowData);
  const btnElement = (action: RowAction<TRowData>) => {
    const iconName =
      typeof action.icon === "string" ? action.icon : action.icon(rowData);
    return (
      <>
        {showTooltip && <PrimeTooltip target={`#${iconName}_${rowIndex}`} />}
        <button
          id={`${iconName}_${rowIndex}`}
          data-cy={action.text}
          data-pr-at="center top-6"
          data-pr-tooltip={tooltipText(action)}
          data-pr-position="top"
          key={`action_${action.text || action.icon}`}
          type="button"
          className={classnames({
            [styles.tableRowActionButton]: true,
            customDatatableRowActionsOnlyOnHover: action.alwaysShow
              ? false
              : showRowsActionsOnHover,
          })}
          onClick={(event) => {
            event.stopPropagation(); // Prevents table row selection

            if (action.onClick) action.onClick(rowData, rowIndex, event);
          }}
          onFocus={() => {}} // Needed for mouse events
          onMouseOver={(event) => {
            if (action.onHover) action.onHover(rowData, rowIndex, event);
          }}
          onMouseLeave={(event) => {
            if (action.onLeave) action.onLeave(rowData, rowIndex, event);
          }}
          aria-label={tooltipText(action) ?? iconName}
        >
          <Icon
            name={iconName}
            size={action.iconSize ?? 24}
            color={
              action.iconVariant === "secondary" ? "Grey-400" : "Primary-700"
            }
          />
        </button>
      </>
    );
  };
  return (
    (!rowActionContextMenu && !isMobile && (
      <span
        className={classnames({
          [styles.tableRowActions]: true,
        })}
      >
        {rowActions.map((action) => {
          if (action?.hideForRow?.(rowData)) {
            return null;
          }
          return btnElement(action);
        })}
      </span>
    )) ||
    ((rowActionContextMenu || isMobile) && (
      <PopupContextMenu
        className="autoWidth"
        position="bottom left"
        trigger={() => (
          <button type="button" className={styles.rowActionContextMenuBtn}>
            <Icon name="dots-horizontal" size={24} color="Grey-400" />
          </button>
        )}
      >
        {rowActions.map((action) => {
          if (action?.hideForRow?.(rowData)) {
            return null;
          }
          const iconName =
            typeof action.icon === "string"
              ? action.icon
              : action.icon(rowData);
          return (
            <PopupContextMenuLinkButton
              key={tooltipText(action) ?? iconName}
              icon={iconName}
              text={action.text || ""}
              onClick={(e) => {
                e?.stopPropagation(); // prevents table row selection
                if (action.onClick) action.onClick(rowData, rowIndex);
              }}
            />
          );
        })}
      </PopupContextMenu>
    ))
  );
};

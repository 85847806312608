import {
  ReactNode,
  Ref,
  useState,
  useImperativeHandle,
  forwardRef,
  ReactElement,
} from "react";
import Popup from "reactjs-popup";
import classnames from "classnames";
import styles from "./TableRowSelectionPopup.module.css";
import useTranslations from "../../../core/i18n/useTranslations";

/** Instance value that is exposed to parent components when using ref */
export declare type TableRowSelectionPopupActions<TRowData> = {
  open: (selectedItems: TRowData[]) => void;
  close: (clear?: boolean) => void;
  toggle: () => void;
};

export interface TableRowSelectionPopupProps<TRowData> {
  /** PopupPosition from reactjs-popup */
  position?: "top center" | "bottom center";
  disabled?: boolean;
  /* Optional. Render custom header. */
  renderHead?: (selectedItems: TRowData[]) => ReactNode;
  /* Required. Render custom body. */
  renderBody: (selectedItems: TRowData[]) => ReactNode;
}

/** Popup that should appear when table rows seleted */
const TableRowSelectionPopupInternal = <TRowData,>(
  {
    position = "bottom center",
    disabled = false,
    renderHead = undefined,
    renderBody,
  }: TableRowSelectionPopupProps<TRowData>,
  ref: Ref<TableRowSelectionPopupActions<TRowData>>
) => {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<TRowData[]>([]);

  // declare limited interface exposed to parent components when using ref
  useImperativeHandle<
    TableRowSelectionPopupActions<TRowData>,
    TableRowSelectionPopupActions<TRowData>
  >(ref, () => {
    const actions: TableRowSelectionPopupActions<TRowData> = {
      open: function open(selectedItemsT) {
        if (selectedItemsT.length === 0) {
          setIsOpen(false);
          setSelectedItems([]);
        } else {
          setIsOpen(true);
          setSelectedItems(selectedItemsT);
        }
      },
      close: function close(clear?: boolean) {
        if (clear) {
          setSelectedItems([]);
        }
        setIsOpen(false);
      },
      toggle: function toggle() {
        setIsOpen(!isOpen);
      },
    };
    return actions;
  });

  return (
    <Popup
      open={isOpen}
      position={position}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      repositionOnResize={false}
      keepTooltipInside
      arrow={false}
      disabled={disabled}
      className={classnames({
        popupTableRowSelection: true,
        popupTableRowSelectionTopCenter: position === "top center",
        popupTableRowSelectionBottomCenter: position === "bottom center",
      })}
    >
      <div className={styles.popupHeader}>
        {renderHead ? (
          renderHead(selectedItems)
        ) : (
          <div>
            {selectedItems.length}{" "}
            {selectedItems.length > 1
              ? t("Common:items_selected")
              : t("Common:item_selected")}
          </div>
        )}
      </div>
      <div className={styles.popupBody}>{renderBody(selectedItems)}</div>
    </Popup>
  );
};

// use forwardRef and cast the exported component to valid type
export const TableRowSelectionPopup = forwardRef(
  TableRowSelectionPopupInternal
) as <TRowData>(
  p: TableRowSelectionPopupProps<TRowData> & {
    ref?: Ref<TableRowSelectionPopupActions<TRowData>>;
  }
) => ReactElement;

export default TableRowSelectionPopup;

import { Dispatch, useEffect, useRef, useState } from "react";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import { Transmitter } from "../../core/api/transmitters/types";
import { getPositioningTransmitters } from "../../core/api/transmitters/transmitters";
import {
  formattedDateString,
  notifyApiErrors,
} from "../../core/helpers/helpers";
import { Column, Spacer } from "../../components/Layout/Layout";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import TableCell, {
  MissingValueTableCell,
} from "../../ui-lib/components/Tables/TableCell";
import Table from "../../ui-lib/components/Tables/Table";
import Radio from "../../ui-lib/components/Radio/Radio";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import { breakpoints, useDimensions } from "../../core/hooks/dimensionProvider";

const columns = (
  t: (key: string) => string,
  isMobile: boolean,
  setSelectedTransmitter: Dispatch<Transmitter | undefined>,
  selectedTransmitter?: Transmitter,
  selectedTransmitterId?: Transmitter
) => [
  {
    header: t("Objects:accessories_table_name"),
    fieldTemplate: (rowData: Transmitter) => (
      <TableCell value={rowData.productName} />
    ),
  },
  {
    header: t("Objects:accessories_table_model"),
    fieldTemplate: (rowData: Transmitter) => (
      <MissingValueTableCell
        value={rowData.model}
        iconColor="Grey-300"
        missingValueText={t("Transmitters:no_model")}
        leftIcon="clipboard-list"
      />
    ),
  },
  {
    header: t("Objects:Labels_identifier"),
    fieldTemplate: (rowData: Transmitter) => (
      <TableCell
        value={rowData.identifier}
        iconColor="Grey-300"
        leftIcon="phone"
        className="cellContentEllipsis"
      />
    ),
  },
  {
    header: t("Transmitters:table_columns_lastCommunicationTest"),
    fieldTemplate: (rowData: Transmitter) => (
      <MissingValueTableCell
        iconColor="Grey-300"
        value={formattedDateString(rowData.lastCommunicationTest)}
        missingValueText={t("Transmitters:no_last_communication")}
        leftIcon="clock"
      />
    ),
  },
  {
    frozen: isMobile,
    alignFrozen: "right",
    className: isMobile ? "action-column" : "",
    editable: true,
    style: { minWidth: "40px", width: "45px" },
    fieldTemplate: (rowData: Transmitter) => (
      <Radio
        checked={rowData.id === selectedTransmitter?.id}
        onChange={() => {
          if (selectedTransmitterId?.id === rowData.id) return;

          setSelectedTransmitter(rowData);
        }}
        name=""
        value=""
        style={{ float: "right" }}
      />
    ),
  },
];

export const AddTransmitterToObjectCreation = ({
  isAddTransmitterOpen,
  setIsAddTransmitterOpen,
  selectedTransmitterId,
  setSelectedTransmitterId,
}: {
  isAddTransmitterOpen: boolean;
  setIsAddTransmitterOpen: Dispatch<boolean>;
  selectedTransmitterId: Transmitter | undefined;
  setSelectedTransmitterId: Dispatch<Transmitter | undefined>;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [transmitters, setTransmitters] = useState<Transmitter[]>();
  const [selectedTransmitter, setSelectedTransmitter] = useState<
    Transmitter | undefined
  >(selectedTransmitterId);
  const [search, setSearch] = useState<string | undefined>();
  const [isSearching, setIsSearching] = useState(false);
  const active = useRef(false);
  const { width } = useDimensions();
  const isMobile = width < breakpoints.desktop;

  async function loadData() {
    try {
      const result = await getPositioningTransmitters(
        {
          page: 1,
          pageSize: 200,
          order: "customer",
          query: search ?? "",
        },
        authenticatedRequest
      );

      if (active.current) {
        setTransmitters(result);
      }
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
    setIsSearching(false);
  }

  const onClose = () => {
    setIsAddTransmitterOpen(false);
  };

  const onSave = () => {
    setSelectedTransmitterId(selectedTransmitter);
    onClose();
  };

  useEffect(() => {
    active.current = true;
    return () => {
      active.current = false;
    };
  }, []);
  useEffect(() => {
    if (isAddTransmitterOpen) loadData();
    return () => setSelectedTransmitter(undefined);
  }, [isAddTransmitterOpen]);

  useEffect(() => {
    setIsSearching(true);
    loadData();
    return () => setIsSearching(false);
  }, [search]);

  return (
    <PrimeModal
      withHeader
      withFooter
      header={t("Objects:transmitter_modal_title")}
      isOpen={isAddTransmitterOpen}
      onClose={onClose}
      contentStyle={{ width: isMobile ? "auto" : "1000px" }}
      submitBtn={{
        text: t("Common:save"),
        variant: "primary",
        onClick: onSave,
        disabled: !selectedTransmitter,
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
    >
      <Column type="top" align="start">
        <p>{t("Objects:transmitter_modal_description")}</p>
        <Spacer size={16} />
        <SearchInput
          placeholder={t("Transmitters:search_placeholder")}
          value={search ?? ""}
          onChange={setSearch}
        />
        <Spacer size={16} />

        <Table<Transmitter>
          columns={
            columns(
              t,
              isMobile,
              setSelectedTransmitter,
              selectedTransmitter,
              selectedTransmitterId
            ) as any
          }
          items={transmitters}
          hideEmptyMessage
          noRowsMessage={
            search
              ? t("Transmitters:transmitters_table_no_match")
              : t("Transmitters:transmitters_table_not_instock")
          }
          showRowHover
          isLoading={isSearching}
          withShowMore
        />
      </Column>
    </PrimeModal>
  );
};

import { AxiosInstance } from "axios";
import JsFileDownloader from "js-file-downloader";
import querystring from "query-string";
import { ApiResponse } from "../../interfaces/Api";
import { getStoredToken } from "../../user/token";
import API_HOST from "../apiHost";
import { Camera } from "../cameras/types";
import useAuthenticatedFetch from "../useAuthenticatedFetch";
import {
  ActivateSubscriptionData,
  ConfigurationFile,
  MedicalTechnicalNotesCreate,
  KnownLocation,
  NewObjectData,
  NewObjectResponse,
  ObjectDetailsModel,
  ObjectDetailsParams,
  TestAlarm,
  MedicalTechnicalNotes,
  ObjectPatch,
  ObjectActivationData,
  IObjectAbsenceModel,
  IObjectAbsenceReasonDataSet,
  IObjectAbsence,
  IAccessLogResponse,
  IHistoryTableData,
  ICalendarActivities,
  IActivityData,
  IActivityCreate,
} from "./types";
import { Transmitter } from "../transmitters/types";
import { BatchAllTableDataParams } from "../common_table";

const getObject = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<ObjectDetailsModel>(`${API_HOST}api/v2/users/${id}`);

const getObjectAbsence = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<IObjectAbsenceModel>(
    `${API_HOST}api/v2/users/${id}/absencecalendar`
  );

const getUserAbsenceType = async (
  id: string | number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<{
    reasonSetName: string;
  }>(`${API_HOST}api/v2/users/${id}/absencereasonsdataset`);

const getAbsenceReason = async (
  type: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<IObjectAbsenceReasonDataSet>(
    `${API_HOST}api/v2/dataset/absencereason/${type || "default"}`
  );

const updateObjectAbsence = async (
  id: string | number,
  absence: IObjectAbsence,
  authenticatedRequest: AxiosInstance
) => {
  const method = absence.id ? "put" : "post";
  return authenticatedRequest[method](
    `${API_HOST}api/v2/users/${id}/absence`,
    JSON.stringify(absence),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const endObjectAbsence = async (
  id: string | number,
  absenceId: number,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put(
    `${API_HOST}api/v2/users/${id}/endabsence/${absenceId}`,
    {},
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const saveNewObject = async (
  newObjectData: NewObjectData,
  authenticatedRequest: AxiosInstance
) => {
  // external creation
  if (newObjectData.externalIntegrationId && newObjectData.externalIdentifier) {
    const data = {
      externalIntegrationId: newObjectData.externalIntegrationId,
      identifier: `${newObjectData.externalIdentifier}`,
      organizationId: newObjectData.organizationId,
      active: newObjectData.active,
    };
    return authenticatedRequest.post(
      `${API_HOST}api/v2/users/externalintegrations`,
      data
    );
  }

  return authenticatedRequest.post<ApiResponse<NewObjectResponse>>(
    `${API_HOST}api/v2/users`,
    JSON.stringify(newObjectData),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const updateObject = async (
  objectId: string,
  objectDetails: ObjectDetailsParams,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.put<ObjectDetailsParams>(
    `${API_HOST}api/v2/users/${objectId}`,
    JSON.stringify(objectDetails),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const patchUpdateObject = async (
  objectId: string,
  objectDetails: ObjectPatch,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.patch<ObjectPatch>(
    `${API_HOST}api/v2/users/${objectId}`,
    JSON.stringify(objectDetails),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

// Object context menu actions
const useTestAlarms = (id: string) =>
  useAuthenticatedFetch<TestAlarm[]>(`${API_HOST}api/v2/users/${id}/testalarm`);

const sendTestAlarm = async (
  id: string,
  testAlarmData: {
    textMessage: string;
    flags: string;
  },
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<ObjectDetailsModel>(
    `${API_HOST}api/v2/users/${id}/testalarm`,
    testAlarmData
  );

const sendDownloadLink = async (
  id: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post<{}>(
    `${API_HOST}api/v2/users/${id}/send/downloadlink`
  );

const deleteObject = async (
  objectId: string | number,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.delete<ApiResponse<any>>(
    `${API_HOST}api/v2/users/${objectId}`
  );
  return result;
};

// Object accessories methods
const getObjectTransmitters = async (
  objectId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<Transmitter[]>(
    `${API_HOST}api/v2/users/${objectId}/transmitters`,
    { headers: { "Content-Type": "application/json" } }
  );

const getUserAccessLog = (
  userId: string,
  params: {
    page?: number;
    pageSize?: number;
    read?: boolean;
    write?: boolean;
  }
) => {
  const settings = querystring.stringify(params);

  return useAuthenticatedFetch<IAccessLogResponse>(
    `${API_HOST}api/v2/users/${userId}/accesslog?${settings}`
  );
};

const getSubscription = (
  objectId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<ObjectActivationData>(
    `${API_HOST}api/v2/users/${objectId}/subscription`
  );

const activateSubscription = async (
  userId: number | string,
  authenticatedRequest: AxiosInstance,
  activationCode?: string,
  subscriptionId?: number
) => {
  const result = await authenticatedRequest.post<
    ApiResponse<ActivateSubscriptionData>
  >(
    `${API_HOST}api/v2/users/${userId}/subscription`,
    JSON.stringify({ subscriptionId: subscriptionId, code: activationCode }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return result;
};

const removeSubscription = async (
  objectId: string,
  authenticatedRequest: AxiosInstance
) => {
  const data = await authenticatedRequest.delete(
    `${API_HOST}api/v2/users/${objectId}/subscription`
  );

  return data;
};

const bulkCancelSubscription = async (
  objectIds: number[],
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete(`${API_HOST}api/v2/users/subscription`, {
    data: {
      userIds: objectIds,
    },
  });

const getLocations = async (
  objectId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<KnownLocation[]>(
    `${API_HOST}api/v2/users/${objectId}/knownlocations`,
    { headers: { "Content-Type": "application/json" } }
  );

const updateLocation = async (
  objectId: number | string,
  location: KnownLocation,
  authenticatedRequest: AxiosInstance
) => {
  const body = {
    precenseAlarmFlags: location.precenseAlarmFlags,
    userAlarmFlags: location.userAlarmFlags,
    userHomeLocation: location.userHomeLocation,
  };

  const result = await authenticatedRequest.put<
    ApiResponse<ActivateSubscriptionData>
  >(`${API_HOST}api/v2/users/${objectId}/knownlocations/${location.id}`, body);
  return result;
};

const getCameras = async (
  objectId: string,
  authenticatedRequest: AxiosInstance
) => {
  const query = querystring.stringify({
    userid: objectId,
  });
  const result = await authenticatedRequest.get<Camera[]>(
    `${API_HOST}api/v2/cameras?${query}`
  );
  return result;
};

const getConfigurations = async (
  objectId: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<ConfigurationFile[]>(
    `${API_HOST}api/v2/users/${objectId}/configurations`,
    { headers: { "Content-Type": "application/json" } }
  );

const pushConfiguration = async (
  objectId: string,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.post<
    ApiResponse<ActivateSubscriptionData>
  >(`${API_HOST}api/users/${objectId}/configure`);
  return result;
};

const downloadConfiguration = async (objectId: string, key: string) => {
  await new JsFileDownloader({
    url: `${API_HOST}api/v2/users/${objectId}/configuration?key=${key}`,
    filename: key,
    headers: [
      { name: "Authorization", value: `Bearer ${getStoredToken()?.token}` },
    ],
  });
};

const requestDeviceLog = async (
  objectId: number,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.post(
    `${API_HOST}api/v2/users/${objectId}/request/log`
  );
  return result;
};

const batchSms = async (
  objectId: string,
  smsData: any,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post(
    `${API_HOST}api/v2/users/${objectId}/send/message`,
    smsData,
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const getMedicalTechnicalNotes = async (
  objectId: string,
  type: string,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.get<MedicalTechnicalNotes[]>(
    `${API_HOST}api/v2/users/${objectId}/${type}`
  );

const saveMedicalTechnicalNote = async (
  objectId: string,
  type: string,
  data: MedicalTechnicalNotesCreate,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post(
    `${API_HOST}api/v2/users/${objectId}/${type}`,
    data
  );

const deleteAllSubscriptions = async (
  limit: number,
  userTable: BatchAllTableDataParams,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.delete(`${API_HOST}api/v2/users/batch/subscription`, {
    data: {
      limit,
      userTable,
    },
  });

const sendSMSToAllObjects = async (
  sendMessage: {
    senderId: string;
    longSms: string;
  },
  limit: number,
  userTable: BatchAllTableDataParams,
  authenticatedRequest: AxiosInstance
) =>
  authenticatedRequest.post(`${API_HOST}api/v2/users/send/batch/message`, {
    sendMessage,
    limit,
    userTable,
  });

const manualSyncObjects = async (
  objectId: number,
  authenticatedRequest: AxiosInstance
) => {
  const result = await authenticatedRequest.post(
    `${API_HOST}api/v2/users/${objectId}/sync`
  );
  return result;
};

const getPlannedActivities = async (
  objectId: number,
  startDate: string,
  endDate: string,
  authenticatedRequest: AxiosInstance
) =>
  await authenticatedRequest.get<ICalendarActivities>(
    `${API_HOST}api/planning/users/${objectId}/activity?StartDate=${startDate}&EndDate=${endDate}`
  );

const getActivity = async (
  userId: number,
  activityId: string,
  authenticatedRequest: AxiosInstance
) =>
  await authenticatedRequest.get<IActivityData>(
    `${API_HOST}api/planning/users/${userId}/activity/${activityId}`
  );

const getActivityHistory = async (
  objectId: number,
  authenticatedRequest: AxiosInstance,
  params: { search: string; filterStatus: string; filterType: string }
) => {
  const data = querystring.stringify(params);
  return await authenticatedRequest.get<IHistoryTableData>(
    `${API_HOST}api/planning/users/${objectId}/activity/history?${data}`
  );
};

const createActivity = async (
  userId: number,
  data: IActivityCreate,
  authenticatedRequest: AxiosInstance
) => {
  await authenticatedRequest.post(
    `${API_HOST}api/planning/users/${userId}/activity`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const updateActivity = async (
  userId: number,
  activityId: string,
  data: IActivityCreate,
  authenticatedRequest: AxiosInstance
) => {
  await authenticatedRequest.put(
    `${API_HOST}api/planning/users/${userId}/activity/${activityId}`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

const deleteActivity = async (
  userId: number,
  activityId: string,
  authenticatedRequest: AxiosInstance
) => {
  await authenticatedRequest.delete(
    `${API_HOST}api/planning/users/${userId}/activity/${activityId}`
  );
};

export {
  getObject,
  getObjectAbsence,
  getUserAbsenceType,
  getAbsenceReason,
  updateObjectAbsence,
  endObjectAbsence,
  saveNewObject,
  updateObject,
  patchUpdateObject,
  useTestAlarms,
  sendTestAlarm,
  sendDownloadLink,
  deleteObject,
  getObjectTransmitters,
  activateSubscription,
  getLocations,
  updateLocation,
  getCameras,
  getConfigurations,
  downloadConfiguration,
  removeSubscription,
  getSubscription,
  pushConfiguration,
  requestDeviceLog,
  batchSms,
  getMedicalTechnicalNotes,
  saveMedicalTechnicalNote,
  bulkCancelSubscription,
  getUserAccessLog,
  deleteAllSubscriptions,
  sendSMSToAllObjects,
  manualSyncObjects,
  getPlannedActivities,
  getActivity,
  getActivityHistory,
  createActivity,
  updateActivity,
  deleteActivity,
};
export type { Transmitter };

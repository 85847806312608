import { Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  Column,
  Row,
  Spacer,
  TwoColGrid,
} from "../../../components/Layout/Layout";
import {
  MedicalTechnicalNotes,
  ObjectDetailsModel,
} from "../../../core/api/objects/types";
import {
  validateEmailIfExists,
  validatePhoneNumberIfExists,
} from "../../../core/helpers/validation";
import useTranslations from "../../../core/i18n/useTranslations";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../core/SaveObjectContext/hooks";
import DatePicker from "../../../ui-lib/components/DateAndTime/DatePicker";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import Tree, { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import TextArea from "../../../ui-lib/components/Inputs/Textarea";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import PhotoWrapperWithUpload from "../../../ui-lib/components/PhotoWrapperWithUpload/PhotoWrapperWithUpload";
import { AlarmHandling } from "../../../core/api/alarmhandlings";
import Button from "../../../ui-lib/components/Button/Button";
import { IItemProp } from "../../../ui-lib/components/Dropdown/DropdownItem";
import TemporaryAlarmHandlingModal from "./TemporaryAlarmHandlingModal";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import { MedicalTechnicalModal } from "../MedicalTechnicalNotes/MedicalTechnicalModal";
import Modal from "../../../ui-lib/components/Popup/Modal";
import { getMedicalTechnicalNotes } from "../../../core/api/objects/objects";
import useUser from "../../../core/user/useUser";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { MedicalTechnicalTable } from "../MedicalTechnicalNotes/MedicalTechnicalTable";
import { getErrorKey } from "../../../components/Errors/ErrorAlert";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import MultiStringInput from "../../../ui-lib/components/MultiInput/MultiStringInput";
import NumberInput from "../../../ui-lib/components/Inputs/NumberInput";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import Divider from "../../../ui-lib/components/Divider/Divider";
import { Role, RoleFeature } from "../../../components/RoleFeature";
import CollapsibleBlock from "../../../ui-lib/components/CollapsibleBlock/CollapsibleBlock";

const languageOptions = [
  { id: 9, key: "english", flag: "GB" },
  { id: 29, key: "swedish", flag: "SE" },
];
const genderOptions = [0, 1, 2, 3];
const maritalOptions = [1, 2, 3, 4, 5, 6];
const hairColorOptions = Array.from(Array(12).keys()).slice(1);

export const ObjectGeneralForm = ({
  defaultValues,
  onSubmit,
  objectId,
  alarmHandlings,
  objectPhoto,
  organizations,
}: {
  defaultValues: ObjectDetailsModel;
  onSubmit: (values: ObjectDetailsModel) => Promise<void>;
  objectId: string;
  alarmHandlings: AlarmHandling[];
  objectPhoto: any;
  organizations: any;
}) => {
  const t = useTranslations();
  const { isLoading: objectLoading } = useSharedObject();
  const [alarmHandlingModalOpened, setAlarmHandlingModalOpened] =
    useState(false);
  const { authenticatedRequest, config, dateFormat } = useUser();
  const isMedicalNotesAvailable = !!config?.show.find(
    (key) => key === "AllowMedicalNotes"
  );
  const isCoreDetailsEditable = !!config?.show.find(
    (key) => key === "User/CoreDetails"
  );
  const hasGbgAccess = config?.show.includes("Gbg");
  const hasHideTemporaryAlarmHandlingPermission = config?.show.includes(
    "HideTemporaryAlarmHandling"
  );
  const hasShowObjectDetailsVariant1Permission = config?.show.includes(
    "ShowObjectDetailsVariant1"
  );

  const [isLoading, setIsLoading] = useState(false);
  const [medicalData, setMedicalData] = useState<MedicalTechnicalNotes[]>();
  const fetchNotes = async () => {
    try {
      setIsLoading(true);
      const result = await getMedicalTechnicalNotes(
        objectId,
        "medicalnotes",
        authenticatedRequest
      );
      setMedicalData(result.data);
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({ message: t(`Errors:${errorKey}`), variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isMedicalNotesAvailable) {
      fetchNotes();
    }
  }, []);

  const formRef = useObjectFormRef();
  const [alarmHandlingsList, setAlarmHandlingsList] = useState<IItemProp[]>([]);

  useEffect(() => {
    if (alarmHandlings && alarmHandlingsList.length === 0) {
      setAlarmHandlingsList(
        alarmHandlings.map(
          (alarm) =>
            ({
              isSelected: defaultValues.alarmHandlingId === alarm.id,
              id: alarm.id,
              name: alarm.name,
            } as IItemProp)
        )
      );
    }
  }, alarmHandlings);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useObjectForm<ObjectDetailsModel>({ defaultValues });

  return (
    <>
      <TemporaryAlarmHandlingModal
        isOpen={alarmHandlingModalOpened}
        setIsOpen={setAlarmHandlingModalOpened}
        alarmHandlings={alarmHandlings}
        objectData={defaultValues}
        objectId={objectId}
      />
      <form
        ref={formRef}
        onSubmit={handleSubmit(async (values) => {
          await onSubmit(values);
          reset(values);
        })}
        style={{ width: "100%", maxWidth: "768px" }}
      >
        <Row align="start">
          <Column align="start">
            <Controller
              name="name"
              control={control}
              rules={{
                required: true,
                minLength: 2,
                maxLength: 60,
              }}
              render={({ field }) => (
                <TextInput
                  required
                  inputRef={field.ref}
                  dataCy="objectName"
                  label={t("Objects:object_name")}
                  value={field.value}
                  onChange={field.onChange}
                  disabled={!isCoreDetailsEditable}
                  validationError={
                    errors?.name && t("Errors:input_object_name")
                  }
                />
              )}
            />
            <Spacer size={16} />
            {defaultValues.sellerName && (
              <p data-cy="objectCreationDate">
                <span className="strong">
                  {t("Objects:labels_admins_seller")}
                </span>
                {": "}
                {defaultValues.sellerName}
              </p>
            )}
          </Column>
          <Spacer size={32} />
          <Column align="start">
            {!!config?.show.find((key) => key === "User/Photo") && (
              <PhotoWrapperWithUpload
                objectId={objectId}
                buttonName="objectThumbnail"
                propertyToContainData="Userimage"
                preLoadedImageData={objectPhoto}
                photoTitle={t("Common:label_image")}
                alternativeString="image thumbnail"
              />
            )}
          </Column>
        </Row>
        <Row align="end">
          {organizations.length > 0 ? (
            <Column align="fill">
              <Controller
                name="organizationId"
                control={control}
                render={({ field }) => (
                  <div>
                    <label htmlFor="addobject_organisation">
                      {t("Objects:organisation")}
                    </label>
                    <Tree
                      placeholder={t("Common:select_organisation")}
                      validationError={
                        errors.organizationId &&
                        t("Objects:organisationvalidation")
                      }
                      items={organizations}
                      disabled={!isCoreDetailsEditable}
                      selectedTreeItem={field.value}
                      onSelectItem={(data: ITreeData) => {
                        if (data?.key !== undefined) {
                          setValue(field.name, data.key as number);
                        }
                      }}
                    />
                  </div>
                )}
              />
            </Column>
          ) : (
            <Column>
              <TextInput
                dataCy="objectOrganisation"
                label={t("Objects:organisation")}
                value={defaultValues.organizationName}
                disabled
              />
            </Column>
          )}
          <Spacer size={32} />
          <Column align="start" type="bottom">
            <p data-cy="objectCreationDate">
              <span className="strong">{t("Common:label_registered_at")}</span>{" "}
              {moment(defaultValues.registrationTime).format("DD MMMM YYYY")}
            </p>
          </Column>
        </Row>

        <Spacer size={16} />

        <Row>
          <Controller
            name="responseCenterId"
            control={control}
            render={() => (
              <TextInput
                dataCy="objectARC"
                label={t("Common:labels_alarm_reception")}
                value={defaultValues.responseCenterName}
                disabled
              />
            )}
          />
          <Spacer size={32} />
          <Controller
            name="alarmHandlingId"
            control={control}
            render={() => (
              <Dropdown
                dataCy="objectAlarmHandling"
                onSelectItem={(item) => {
                  const updatedHandlingList = alarmHandlingsList.map(
                    (alarm) => ({
                      ...alarm,
                      isSelected: alarm.id === item.id,
                    })
                  );
                  setAlarmHandlingsList(updatedHandlingList);
                  setValue("alarmHandlingId", item.id as number);
                }}
                items={alarmHandlingsList}
                title={t("AlarmHistory:alarm_query_filter_alarm_handling")}
                selectedItem={alarmHandlingsList.find(
                  (alarm) => alarm.isSelected
                )}
                disabled={!alarmHandlingsList.length || !isCoreDetailsEditable}
              />
            )}
          />
        </Row>
        <Spacer size={16} />
        <Row type="left" align="end">
          {defaultValues.nextSubscriptionId &&
            !hasHideTemporaryAlarmHandlingPermission && (
              <Button
                variant="popup"
                text={
                  defaultValues.temporaryAlarmHandlingEnd &&
                  moment.utc() < moment(defaultValues.temporaryAlarmHandlingEnd)
                    ? t("Objects:edit_temporary_alarm_handling")
                    : t("Objects:set_temporary_alarm_handling")
                }
                onClick={() => setAlarmHandlingModalOpened(true)}
                disabled={!isCoreDetailsEditable || objectLoading}
              />
            )}
        </Row>

        <Spacer size={16} />

        {config?.show.includes("ShareAlarm") && (
          <Row type="fill">
            <Controller
              name="shareAlarmWith"
              control={control}
              render={({ field }) => {
                const length = field.value?.length ?? 0;
                const values = length > 0 ? field.value?.split(",") : [];

                return (
                  <MultiStringInput
                    maxValues={6}
                    label={t("Common:labels_share_alarm_with")}
                    description={t("Common:share_alarm_description")}
                    values={values}
                    setValues={(updated) => {
                      const value = updated.join(",");
                      setValue(field.name, (value || "").toUpperCase(), {
                        shouldDirty: true,
                      });
                    }}
                  />
                );
              }}
            />
          </Row>
        )}

        <Spacer size={16} />

        <RoleFeature requires={[Role.BetaAccess]}>
          <TwoColGrid>
            <Controller
              name="culture"
              control={control}
              render={({ field }) => {
                const items = languageOptions.map((l) => ({
                  id: l.id,
                  name: t(`Common:language_${l.key}`),
                  flag: l.flag,
                  isSelected: field.value === l.id,
                }));

                const selectedItem = items.find((i) => i.isSelected);

                return (
                  <Dropdown
                    items={items}
                    selectedItem={selectedItem}
                    onSelectItem={(item) =>
                      setValue("culture", item.id as number)
                    }
                    title={t("Common:labels_language")}
                    placeholder={t("Common:labels_select_language")}
                    disabled={!isCoreDetailsEditable}
                  />
                );
              }}
            />
          </TwoColGrid>
          <Spacer size={32} />
        </RoleFeature>

        <Divider />
        <Spacer size={32} />

        {config?.show.includes("User/BaseInformation") && (
          <>
            {!config?.show.includes("CareiumHideFields") && (
              <>
                <h2>{t("Objects:general_personal_details_title")}</h2>
                <Spacer size={8} />
                <p>{t("Objects:general_personal_details_description")}</p>

                <Spacer size={16} />

                {!hasShowObjectDetailsVariant1Permission && (
                  <>
                    <Row type="fill">
                      <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            label={t("Common:labels_title")}
                            value={field.value}
                            onChange={field.onChange}
                            placeholder={t("Common:placeholder_object_title")}
                          />
                        )}
                      />
                      <Spacer size={32} />
                      <Controller
                        name="profession"
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            label={t("Common:labels_profession")}
                            value={field.value}
                            placeholder={t("Common:placeholder_profession")}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </Row>

                    <Spacer size={16} />
                  </>
                )}

                <Row type="fill">
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      maxLength: 60,
                      validate: validateEmailIfExists,
                    }}
                    render={({ field }) => (
                      <TextInput
                        label={t("Common:email")}
                        inputRef={field.ref}
                        value={field.value}
                        onChange={field.onChange}
                        placeholder={t("Common:placeholder_email")}
                        validationError={
                          errors?.email && t("Errors:input_email_format")
                        }
                      />
                    )}
                  />
                  <Spacer size={32} />
                  <Controller
                    name="nationalIdNumber"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        label={t("Common:labels_id_number")}
                        value={field.value}
                        onChange={(e) => {
                          setValue(field.name, e.target.value.toUpperCase());
                        }}
                      />
                    )}
                  />
                </Row>
                <Spacer size={16} />
              </>
            )}

            <Row type="fill">
              <TwoColGrid style={{ columnGap: "32px" }}>
                {!hasShowObjectDetailsVariant1Permission && (
                  <Controller
                    name="externalId"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        label={t("Common:labels_external_id")}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                )}
                {!config?.show.includes("CareiumHideFields") && (
                  <Controller
                    name="protectedIdentidy"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        checked={field.value}
                        onChange={field.onChange}
                        style={{ padding: 0 }}
                        label={t("Common:labels_protected_identity")}
                      />
                    )}
                  />
                )}
              </TwoColGrid>
            </Row>
            {!config?.show.includes("CareiumHideFields") && (
              <>
                <Spacer size={16} />
                <Controller
                  name="additionalInfo"
                  control={control}
                  rules={{ maxLength: 2500 }}
                  render={({ field }) => (
                    <TextArea
                      label={t("Common:labels_additional_info")}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors.additionalInfo &&
                        t("Errors:max_chars_exceeded").replace("{0}", "2500")
                      }
                    />
                  )}
                />

                <Spacer size={16} />

                <Controller
                  name="medicalCondition"
                  rules={{ maxLength: 1000 }}
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      label={t("Common:labels_condition")}
                      inputRef={field.ref}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors?.medicalCondition &&
                        t("Errors:max_chars_exceeded").replace("{0}", "1000")
                      }
                    />
                  )}
                />

                <Spacer size={16} />

                <Controller
                  name="medication"
                  rules={{ maxLength: 1000 }}
                  control={control}
                  render={({ field }) => (
                    <TextArea
                      label={t("Common:labels_medication")}
                      inputRef={field.ref}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors?.medication &&
                        t("Errors:max_chars_exceeded").replace("{0}", "1000")
                      }
                    />
                  )}
                />
              </>
            )}
            {!hasShowObjectDetailsVariant1Permission &&
              !config?.show.includes("CareiumHideFields") && (
                <>
                  <Spacer size={16} />

                  <Controller
                    name="carePlan"
                    rules={{ maxLength: 1000 }}
                    control={control}
                    render={({ field }) => (
                      <TextArea
                        label={t("Common:labels_care_plan")}
                        inputRef={field.ref}
                        value={field.value}
                        onChange={field.onChange}
                        validationError={
                          errors?.carePlan &&
                          t("Errors:max_chars_exceeded").replace("{0}", "1000")
                        }
                      />
                    )}
                  />
                </>
              )}

            {!hasShowObjectDetailsVariant1Permission &&
              !config?.show.includes("CareiumHideFields") && (
                <>
                  <Spacer size={16} />

                  <Row type="fill">
                    <Controller
                      name="supportingAids"
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          label={t("Common:labels_supporting_aids")}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Row>
                </>
              )}

            {isMedicalNotesAvailable &&
              !config?.show.includes("CareiumHideFields") && (
                <>
                  <Spacer size={20} />

                  <Column type="top" align="start">
                    {isLoading ? (
                      <LoadingSpinner theme="primary" />
                    ) : (
                      <>
                        <Row style={{ width: "100%", flex: 0 }}>
                          <h2>{t("Objects:medical_notes")}</h2>
                          <Spacer />
                          <Modal
                            trigger={() => (
                              <div>
                                <Button
                                  type="button"
                                  variant="secondary"
                                  image="document-text"
                                  text={t("Objects:add_note")}
                                />
                              </div>
                            )}
                          >
                            {(close) => (
                              <MedicalTechnicalModal
                                onClose={close}
                                onAdd={fetchNotes}
                                title={t("Objects:add_medical_notes")}
                                type="medicalnotes"
                                objectId={objectId}
                              />
                            )}
                          </Modal>
                        </Row>

                        <Spacer size={16} />
                        <MedicalTechnicalTable notes={medicalData || []} />
                      </>
                    )}
                  </Column>
                </>
              )}

            <Spacer size={32} />

            {!config?.show.includes("CareiumHideFields") && (
              <CollapsibleBlock
                withScroll
                title={t("Objects:personal_extra_section")}
              >
                <>
                  <Spacer size={32} />
                  <h2>{t("Objects:general_personal_advanced_title")}</h2>
                  <Spacer size={8} />
                  <p>{t("Objects:general_personal_advanced_description")}</p>

                  <Spacer size={16} />

                  {!hasGbgAccess && (
                    <Row type="fill">
                      <Controller
                        name="exchangeTelephoneNumber"
                        control={control}
                        rules={{
                          validate: validatePhoneNumberIfExists,
                        }}
                        render={({ field }) => (
                          <TextInput
                            label={t("Common:labels_showen_number")}
                            inputRef={field.ref}
                            placeholder={t("Common:phone_number_placeholder")}
                            value={field.value}
                            onChange={field.onChange}
                            validationError={
                              errors?.exchangeTelephoneNumber &&
                              t("Objects:Validation_identifier_format")
                            }
                          />
                        )}
                      />
                    </Row>
                  )}

                  <Spacer size={16} />

                  <Row type="fill">
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          label={t("Common:labels_dob")}
                          value={
                            field.value ? new Date(field.value) : undefined
                          }
                          placeholder={dateFormat?.split("/").join("-")}
                          onChange={(dateString) => {
                            field.onChange(
                              dateString
                                ? DateTimeHelper.formatDateISOFormat(dateString)
                                : null
                            );
                          }}
                          dateFormat={dateFormat?.split("/").join("-")}
                        />
                      )}
                    />
                    <Spacer size={32} />
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          items={genderOptions.map((id) => ({
                            id,
                            name: t(`CommonEnum:Gender0${id}`),
                            isSelected: field.value === id,
                          }))}
                          selectedItem={
                            field.value
                              ? {
                                  name: t(`CommonEnum:Gender0${field.value}`),
                                  id: field.value || "",
                                  isSelected: true,
                                }
                              : undefined
                          }
                          onSelectItem={(item) => {
                            setValue("gender", item.id as number);
                            item.isSelected = true;
                          }}
                          title={t("Common:labels_gender")}
                          placeholder={t("Common:placeholder_gender")}
                          validationError={
                            errors?.gender && t("Objects:Validation_reception")
                          }
                        />
                      )}
                    />
                  </Row>

                  {!hasShowObjectDetailsVariant1Permission && (
                    <>
                      <Spacer size={16} />

                      <Row type="fill">
                        <Controller
                          name="height"
                          control={control}
                          rules={{
                            validate: (v) => {
                              if (!v) return true;
                              return v <= 10000 && v % 1 === 0;
                            },
                          }}
                          render={({ field }) => (
                            <NumberInput
                              inputRef={field.ref}
                              label={t("Common:labels_height")}
                              value={`${field.value}`}
                              placeholder={t("Common:placeholder_height")}
                              onChange={(e) => {
                                if (e.target.value) {
                                  setValue(field.name, +e.target.value);
                                } else {
                                  setValue(field.name, null);
                                }
                              }}
                              validationError={
                                errors?.height &&
                                t(
                                  "Errors:value_should_be_less_and_integer"
                                ).replace("{0}", "10000")
                              }
                            />
                          )}
                        />
                        <Spacer size={32} />
                        <Controller
                          name="weight"
                          control={control}
                          rules={{
                            validate: (v) => {
                              if (!v) return true;
                              return v <= 10000 && v % 1 === 0;
                            },
                          }}
                          render={({ field }) => (
                            <NumberInput
                              inputRef={field.ref}
                              label={t("Common:labels_weight")}
                              value={`${field.value}`}
                              placeholder={t("Common:placeholder_weight")}
                              onChange={(e) => {
                                if (e.target.value) {
                                  setValue(field.name, +e.target.value);
                                } else {
                                  setValue(field.name, null);
                                }
                              }}
                              validationError={
                                errors?.weight &&
                                t(
                                  "Errors:value_should_be_less_and_integer"
                                ).replace("{0}", "10000")
                              }
                            />
                          )}
                        />
                      </Row>
                    </>
                  )}

                  <Spacer size={16} />

                  <Row type="fill">
                    {!hasShowObjectDetailsVariant1Permission && (
                      <Controller
                        name="hairColour"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            items={hairColorOptions.map((id) => ({
                              id,
                              name: t(`CommonEnum:HairColour${id}`),
                              isSelected: field.value === id,
                            }))}
                            selectedItem={
                              field.value
                                ? {
                                    name: t(
                                      `CommonEnum:HairColour${field.value}`
                                    ),
                                    id: field.value || "",
                                    isSelected: true,
                                  }
                                : undefined
                            }
                            onSelectItem={(item) => {
                              setValue("hairColour", item.id as number);
                              item.isSelected = true;
                            }}
                            title={t("Common:labels_hair_color")}
                            placeholder={t("Common:placeholder_hair_color")}
                            width={376}
                          />
                        )}
                      />
                    )}
                    <Spacer size={32} />
                    <Controller
                      name="maritalStatus"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          items={maritalOptions.map((id) => ({
                            id,
                            name: t(`CommonEnum:MaritalStatus_${id}`),
                            isSelected: field.value === id,
                          }))}
                          selectedItem={
                            field.value
                              ? {
                                  name: t(
                                    `CommonEnum:MaritalStatus_${field.value}`
                                  ),
                                  id: field.value || "",
                                  isSelected: true,
                                }
                              : undefined
                          }
                          onSelectItem={(item) => {
                            setValue("maritalStatus", +item.id);
                            item.isSelected = true;
                          }}
                          title={t("Common:labels_marital_status")}
                          placeholder={t(
                            "Common:placelholder_select_marital_status"
                          )}
                          width={376}
                        />
                      )}
                    />
                  </Row>

                  {!hasShowObjectDetailsVariant1Permission && (
                    <>
                      <Spacer size={16} />

                      <Row type="fill">
                        <Controller
                          name="debtorNumber"
                          control={control}
                          rules={{ maxLength: 50 }}
                          render={({ field }) => (
                            <TextInput
                              label={t("Common:labels_debtor_number")}
                              inputRef={field.ref}
                              placeholder={t("Common:language_debtor_number")}
                              value={field.value}
                              onChange={field.onChange}
                              validationError={
                                errors?.debtorNumber &&
                                t("Errors:max_chars_exceeded").replace(
                                  "{0}",
                                  "50"
                                )
                              }
                            />
                          )}
                        />
                        <Spacer size={32} />
                        <Controller
                          name="personalOther"
                          control={control}
                          rules={{ maxLength: 75 }}
                          render={({ field }) => (
                            <TextInput
                              inputRef={field.ref}
                              label={t("Common:labels_personal_other")}
                              placeholder={t("Common:language_personal_other")}
                              value={field.value}
                              onChange={field.onChange}
                              validationError={
                                errors?.personalOther &&
                                t("Errors:max_chars_exceeded").replace(
                                  "{0}",
                                  "75"
                                )
                              }
                            />
                          )}
                        />
                      </Row>
                    </>
                  )}

                  <Spacer size={32} />

                  <h2>{t("Objects:general_personal_language_title")}</h2>
                  <Spacer size={8} />
                  <p>{t("Objects:general_personal_language_description")}</p>

                  <Spacer size={16} />

                  <Row type="fill">
                    <Controller
                      name="firstLanguage"
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          label={`${t("Common:part_label_first")} ${t(
                            "Common:part_label_language"
                          )}`}
                          value={field.value}
                          placeholder={t("Common:language_english")}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    <Spacer size={32} />
                    <Controller
                      name="secondLanguage"
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          label={`${t("Common:part_label_second")} ${t(
                            "Common:part_label_language"
                          )}`}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Row>

                  <Spacer size={16} />

                  <Row type="fill">
                    <Controller
                      name="thirdLanguage"
                      control={control}
                      render={({ field }) => (
                        <TextInput
                          label={`${t("Common:part_label_third")} ${t(
                            "Common:part_label_language"
                          )}`}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    <Spacer size={32} />
                    {!hasShowObjectDetailsVariant1Permission && (
                      <Controller
                        name="citizenShip"
                        control={control}
                        render={({ field }) => (
                          <TextInput
                            label={t("Common:label_citizenship")}
                            value={field.value}
                            placeholder={t("Common:placeholder_citizenship")}
                            onChange={field.onChange}
                          />
                        )}
                      />
                    )}
                  </Row>

                  <Spacer size={32} />
                  {!hasShowObjectDetailsVariant1Permission && (
                    <>
                      <h2>
                        {t("Objects:general_personal_miscellaneous_title")}
                      </h2>

                      <Spacer size={16} />

                      <Row type="fill">
                        <Controller
                          name="insurance"
                          control={control}
                          render={({ field }) => (
                            <TextInput
                              label={t("Common:labels_insurance_information")}
                              value={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                        <Spacer size={32} />
                        <Controller
                          name="keyPlacement"
                          control={control}
                          render={({ field }) => (
                            <TextInput
                              label={t("Common:labels_key_marking")}
                              value={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </Row>
                      <Spacer size={16} />

                      <Row>
                        <Controller
                          name="referalNotes"
                          control={control}
                          rules={{ maxLength: 1000 }}
                          render={({ field }) => (
                            <TextArea
                              inputRef={field.ref}
                              label={t("Common:labels_referal_notes")}
                              placeholder={t(
                                "Common:placeholder_referal_notes"
                              )}
                              value={field.value}
                              onChange={field.onChange}
                              validationError={
                                errors?.referalNotes &&
                                t("Errors:max_chars_exceeded").replace(
                                  "{0}",
                                  "1000"
                                )
                              }
                            />
                          )}
                        />
                      </Row>
                      <Spacer size={16} />

                      <Row>
                        <Controller
                          name="generalNotes"
                          control={control}
                          rules={{ maxLength: 1000 }}
                          render={({ field }) => (
                            <TextArea
                              inputRef={field.ref}
                              label={t("Common:labels_general_notes")}
                              placeholder={t(
                                "Common:placeholder_general_notes"
                              )}
                              value={field.value}
                              onChange={field.onChange}
                              validationError={
                                errors?.generalNotes &&
                                t("Errors:max_chars_exceeded").replace(
                                  "{0}",
                                  "1000"
                                )
                              }
                            />
                          )}
                        />
                      </Row>
                    </>
                  )}
                </>
              </CollapsibleBlock>
            )}
          </>
        )}
      </form>
    </>
  );
};

import { observer } from "mobx-react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { uniqBy } from "lodash";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import { useSubNav } from "../../../components/LoggedinPage/SubNavContext";
import {
  storeTableState,
  useStoredTableState,
} from "../../../core/hooks/filters/useStoredTableState";
import useTranslations from "../../../core/i18n/useTranslations";
import Divider from "../../../ui-lib/components/Divider/Divider";
import PageHeader from "../../../ui-lib/components/PageHeader/PageHeader";
import styles from "../../Layout.module.css";
import { PAGE_ID as parentId } from "../Administrators";
import RoleTemplateTable from "./RoleTemplateTable";
import {
  getRoleTemplates,
  useRoleTemplates,
} from "../../../core/api/roleTemplates/roleTemplates";
import { useQueryState } from "../../../core/hooks/filters/useQueryState";
import { RoleTemplate } from "../../../core/api/roleTemplates/types";
import SearchInput from "../../../ui-lib/components/Inputs/SearchInput";
import Modal, { ModalActions } from "../../../ui-lib/components/Popup/Modal";
import { AddRoleTemplate } from "./AddRoleTemplate";
import { useOrganizationsTree } from "../../../core/api/organizations/organizations";
import useUser from "../../../core/user/useUser";
import { EditRoleTemplate } from "./EditRoleTemplate";
import { DeleteRoleTemplateModal } from "./DeleteRoleTemplateModal";
import {
  searchStringParser,
  urlSearchParser,
} from "../../../ui-lib/utils/urlSearchParser";
import {
  useDateTimeFormats,
  useTimezones,
} from "../../../core/api/administrators/administrators";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { useModalIdHook } from "../../../core/hooks/modalIdsHandlers/useIdModalHook";
import { useFiltersByAdminType } from "../../../core/hooks/filters/useFiltersByAdminType";
import { IItemProp } from "../../../ui-lib/components/Dropdown/DropdownItem";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import Tree, { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import { ClearFilter } from "../../../ui-lib/components/Tables/CleatFilters";
import { getResponseCenters } from "../../../core/api/responsecenters/responsecenters";
import { notifyApiErrors } from "../../../core/helpers/helpers";

const parentPath = "/adminportal/administrators";
export const PAGE_ID = "adminportal/roletemplates";

const INITIAL_PAGE_SETTINGS = {
  page: 1,
  pageSize: 10,
  total: 0,
};

const subMenuItems = (url: string) => [
  { key: "Common:role_templates", to: url },
];

const RoleTemplatesWrapper = ({
  onCreateTemplate,
  onEditTemplate,
  resetId,
  children,
  selected,
  isEditTemplateOpen,
  setIsEditTemplateOpen,
  organisations,
}: {
  onCreateTemplate: () => void;
  onEditTemplate: () => void;
  resetId?: () => void;
  children: ReactNode;
  selected?: RoleTemplate;
  isEditTemplateOpen: boolean;
  setIsEditTemplateOpen: any;
  organisations: ITreeData[];
}) => {
  const t = useTranslations();
  const user = useUser();

  const { data: timezones, isLoading: isTimezonesLoading } = useTimezones();
  const { data: dateTimeFormats, isLoading: isDateFormatsLoading } =
    useDateTimeFormats();

  if (isTimezonesLoading || isDateFormatsLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <>
      <Row align="start" style={{ width: "100%" }}>
        <Column
          style={{ width: "100%", height: "100vh", overflow: "hidden" }}
          type="top"
        >
          <PageHeader title={t("Common:role_templates")} icon="identification">
            {!!user.config?.show.find(
              (key) => key === "UpdateRoleTemplates"
            ) && (
              <AddRoleTemplate
                onSubmit={onCreateTemplate}
                organisations={organisations}
                timezones={timezones}
                dateTimeFormats={dateTimeFormats}
              />
            )}
          </PageHeader>
          <Divider />
          <Column
            className={styles.wrapper}
            style={{
              height: "auto",
              overflowY: "auto",
            }}
          >
            <Column
              className={styles.content}
              type="top"
              align="start"
              style={{ padding: "32px" }}
            >
              {children}
            </Column>
          </Column>
        </Column>
      </Row>
      {selected && (
        <EditRoleTemplate
          roleTemplateId={selected.id}
          organisations={organisations}
          onClose={() => {}}
          onSubmit={onEditTemplate}
          isEditTemplateOpen={isEditTemplateOpen}
          setIsEditTemplateOpen={setIsEditTemplateOpen}
          timezones={timezones}
          dateTimeFormats={dateTimeFormats}
          resetId={resetId}
        />
      )}
    </>
  );
};

const RoleTemplates = () => {
  const { path } = useRouteMatch();

  const t = useTranslations();
  const { authenticatedRequest } = useUser();

  storeTableState(PAGE_ID);
  const { data: organisations } = useOrganizationsTree();
  const savedState = useStoredTableState(PAGE_ID);
  const searchState = urlSearchParser(savedState);
  const [pageSettings, setPageSettings] = useState(INITIAL_PAGE_SETTINGS);
  const [tableSettings, setTableSettings] = useQueryState<{ q: string }>({
    q: searchStringParser(searchState?.q) ?? "",
  });

  const [searchParam, setSearchParam] = useState<string>(
    searchStringParser(searchState?.q) ?? ""
  );

  const { FiltersByAdminType, setFiltersByAdminType, resetFiltersByAdminType } =
    useFiltersByAdminType(searchState);

  const [id, setId, resetId] = useModalIdHook(
    searchState?.roleId || undefined,
    "roleId"
  );

  const adminTypeOptions: IItemProp[] = [
    {
      id: "Admin",
      name: t("Common:admin"),
      isSelected: FiltersByAdminType.adminType === "Admin",
    },
    {
      id: "Respondent",
      name: t("Common:respondent"),
      isSelected: FiltersByAdminType.adminType === "Respondent",
    },
  ];

  const [tableData, setTableData] = useState<RoleTemplate[]>([]);
  const [selected, setSelected] = useState<RoleTemplate>();
  const [arcList, setArcList] = useState<IItemProp[]>([]);

  const deleteModalRef = useRef<ModalActions>(null);
  const [isEditTemplateOpen, setIsEditTemplateOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [arcLoading, setArcLoading] = useState(false);

  const fetchAlarmReceptions = async () => {
    const query = {
      page: 1,
      pageSize: 10000,
    };
    try {
      setArcLoading(true);
      const data = await getResponseCenters(query, authenticatedRequest);
      const alarmTreeItems = data.data.map((item) => ({
        id: item.id!,
        name: item.name!,
        isSelected: item.id === Number(FiltersByAdminType.responsecenter),
      }));
      setArcList(alarmTreeItems);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setArcLoading(false);
    }
  };

  const { data, isLoading } = useRoleTemplates({
    page: pageSettings.page,
    pageSize: pageSettings.pageSize,
    q: tableSettings.q,
    organization: FiltersByAdminType?.orgId,
    adminType: FiltersByAdminType?.adminType,
    responsecenter: FiltersByAdminType?.responsecenter,
  });

  const onClear = () => {
    setSearchParam("");
    setPageSettings(INITIAL_PAGE_SETTINGS);
    setTableData([]);
    resetFiltersByAdminType();
    setArcList(
      arcList.map((arc) => {
        arc.isSelected = false;
        return arc;
      })
    );
  };

  const onTableUpdate = async () => {
    setLoading(true);
    try {
      const { data: templates } = await getRoleTemplates(
        {
          ...FiltersByAdminType,
          ...pageSettings,
          ...tableSettings,
          page: 1,
        },
        authenticatedRequest
      );
      setTableData(templates);
      setPageSettings(INITIAL_PAGE_SETTINGS);
    } catch (error: any) {
      notifyApiErrors(error.response?.data.errors);
    } finally {
      setLoading(false);
    }
  };

  const selectedAdminType = adminTypeOptions.find((type) => type.isSelected);

  useEffect(() => {
    if (FiltersByAdminType.adminType === "Respondent" && !arcList.length) {
      fetchAlarmReceptions();
    }
  }, [FiltersByAdminType.adminType]);

  useEffect(() => {
    if (id.id) {
      const currentRole = tableData.find((role) => role.id === +id.id!);
      setIsEditTemplateOpen(true);
      setSelected(currentRole);
    } else {
      setSelected(undefined);
    }
  }, [tableData, id.id]);

  // Append new data to list once updated
  useEffect(() => {
    if (data) {
      setTableData((prev) => {
        const updated = [...prev, ...data];
        setPageSettings({ ...pageSettings, total: updated.length });
        return uniqBy(updated, "id");
      });
    }
  }, [data]);

  return (
    <Switch>
      <Route path={`${path}`}>
        <RoleTemplatesWrapper
          onCreateTemplate={onTableUpdate}
          onEditTemplate={onTableUpdate}
          selected={selected}
          isEditTemplateOpen={isEditTemplateOpen}
          setIsEditTemplateOpen={setIsEditTemplateOpen}
          resetId={resetId}
          organisations={organisations}
        >
          <Row className={styles.settingsRow}>
            <SearchInput
              placeholder={t("Administrator:roletemplate_search_placeholder")}
              value={searchParam ?? ""}
              limit={1}
              onChange={(value) => {
                setSearchParam(value);
                setTableSettings((prev) => ({ ...prev, q: value }));
                setTableData([]);
                setPageSettings(INITIAL_PAGE_SETTINGS);
              }}
            />
          </Row>
          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />
          <Row style={{ flex: "unset" }}>
            <Dropdown
              withClearItem
              placeholder={`--${t("Administrator:type")}--`}
              width={248}
              selectedItem={selectedAdminType}
              items={adminTypeOptions}
              onSelectItem={(item) => {
                if (item.id === FiltersByAdminType.adminType) return;
                // Clear all previosly selected filters
                resetFiltersByAdminType();
                setTableData([]);
                setPageSettings(INITIAL_PAGE_SETTINGS);
                // Set new filter value
                setFiltersByAdminType({
                  adminType: item.id === -1 ? undefined : item.id.toString(),
                });
                setArcList(
                  arcList.map((arc) => {
                    arc.isSelected = false;
                    return arc;
                  })
                );
              }}
            />
            <Spacer size={8} />
            {FiltersByAdminType.adminType == "Admin" && (
              <Tree
                showClearOption
                className={styles.treeFilter}
                placeholder={`--${t("Common:organisation")}--`}
                items={organisations}
                selectedTreeItem={Number(FiltersByAdminType.orgId)}
                onSelectItem={(treeItem) => {
                  setTableData([]);
                  setPageSettings(INITIAL_PAGE_SETTINGS);
                  setFiltersByAdminType({
                    orgId: treeItem?.key.toString(),
                  });
                }}
                disabled={!organisations.length}
              />
            )}
            {FiltersByAdminType.adminType == "Respondent" && (
              <Dropdown
                withClearItem
                withFilter
                maxVisible={9}
                bodyWidth={350}
                width={250}
                placeholder={`--${t("Common:labels_alarm_reception")}--`}
                items={arcList || []}
                disabled={arcLoading || !arcList?.length}
                isLoading={arcLoading}
                selectedItem={arcList?.find((arc) => arc?.isSelected)}
                onSelectItem={(item) => {
                  if (item.id.toString() === FiltersByAdminType.responsecenter)
                    return;
                  setTableData([]);
                  setPageSettings(INITIAL_PAGE_SETTINGS);
                  setFiltersByAdminType({
                    ...FiltersByAdminType,
                    responsecenter:
                      +item.id === -1 ? undefined : item.id.toString(),
                  });
                  setArcList((prev) => {
                    return prev.map((arc) => {
                      arc.isSelected = arc.id === item.id;
                      return arc;
                    });
                  });
                }}
              />
            )}
            <Spacer size={8} />
            <ClearFilter
              text={t("Common:labels_clear_all_filters")}
              onClearClick={onClear}
              filtersToWatch={[{ q: searchParam }, FiltersByAdminType]}
            />
          </Row>
          <Column className={styles.tableContainer} type="top">
            <RoleTemplateTable
              roleTemplates={tableData || []}
              isLoading={isLoading || arcLoading || loading}
              pageSettings={pageSettings}
              onPageSettingsChange={setPageSettings}
              onStartDelete={(role: RoleTemplate) => {
                setSelected(role);
                deleteModalRef?.current?.open();
              }}
              onStartEdit={(role: RoleTemplate) => {
                setSelected(role);
                setId?.(role.id);
                setIsEditTemplateOpen(true);
              }}
            />
          </Column>
          <Modal modalRef={deleteModalRef}>
            {(close) => (
              <DeleteRoleTemplateModal
                templateId={selected?.id}
                onClose={close}
                onDelete={() => {
                  close();
                  onTableUpdate();
                }}
              />
            )}
          </Modal>
        </RoleTemplatesWrapper>
      </Route>
    </Switch>
  );
};

const RoleTemplateContainer = () => {
  const t = useTranslations();

  const { url } = useRouteMatch();
  const { setSubNav } = useSubNav();
  const tableState = useStoredTableState(parentId);
  const backUrl = tableState ? `${parentPath}${tableState}` : parentPath;

  useEffect(() => {
    const nav = {
      menu: subMenuItems(url),
      backUrl,
      backText: t("Menu:administrators"),
    };

    setSubNav(nav);

    return () => {
      setSubNav(undefined);
    };
  }, []);

  return <RoleTemplates />;
};

export default observer(RoleTemplateContainer);

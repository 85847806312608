import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Spacer } from "../../Layout/Layout";
import styles from "./ContactInfo.module.css";
import useTranslations from "../../../core/i18n/useTranslations";
import useBranding from "../../../core/branding/useBranding";
import useUser from "../../../core/user/useUser";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import { getSupportInfo } from "../../../core/api/administrators/administrators";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import { SupportData } from "../../../core/api/administrators/types";
import Icon from "../../../ui-lib/components/Icon";

const InfoBlock = ({
  title,
  email,
  phoneNumber,
}: {
  title: string;
  email: string;
  phoneNumber: string;
}) => (
  <>
    <h3 className={styles.light}>{title}</h3>
    <Spacer size={4} />
    <p className={styles.semibold}>
      <a href={`mailto:${email}`}>{email}</a>
    </p>
    <p className={styles.semibold}>
      <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
    </p>
  </>
);

const ContactInfo = ({
  onClose,
  asModal,
}: {
  onClose?: () => void;
  asModal?: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [brandInfo, setBrandInfo] = useState<SupportData>();
  const currentBrand = useBranding().customerKey;

  const fetchBrandingSupport = async () => {
    try {
      setIsLoading(true);
      const results = await getSupportInfo(authenticatedRequest, currentBrand);
      setBrandInfo(results.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBrandingSupport();
  }, []);

  if (isLoading || !brandInfo) {
    return (
      <div className={styles.container}>
        <LoadingSpinner theme="primary" />
      </div>
    );
  }

  return (
    <div className={asModal ? styles.container : ""}>
      {asModal && (
        <>
          <button
            onClick={onClose}
            onKeyDown={(e) => {
              if (e.code === "Space" || e.code === "Enter") {
                onClose?.();
              }
            }}
            className={styles.closeButton}
            aria-label="Close"
            tabIndex={0}
          >
            <Icon name="x" />
          </button>
          <h2 className="subTitle">{t("Home:contact_info")}</h2>
          <Spacer size={16} />
        </>
      )}
      <p className={styles.light}>
        <span>{t("Home:description_contact_info_1")}</span>
        <Link
          to={{ pathname: "https://status.skyresponse.com" }}
          target="_blank"
        >
          {t("Home:description_contact_link")}
        </Link>
        <span>{t("Home:description_contact_info_2")}</span>
      </p>
      <Spacer size={16} />
      <InfoBlock
        title={t("Home:technical_support")}
        email={brandInfo.emailTechnicalSupport}
        phoneNumber={brandInfo.phoneTechnicalSupport}
      />
      <Spacer size={16} />
      <InfoBlock
        title={t("Home:administration")}
        email={brandInfo.emailAdministrative}
        phoneNumber={brandInfo.phoneAdministrative}
      />
    </div>
  );
};

export default ContactInfo;

/* eslint-disable no-case-declarations */
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import he from "he";
import BulkDeleteModal from "../../components/BulkDeleteModal";
import ExportObjectsSettings from "../../components/ExportModal/ExportObjectsSettings";
import { Row, Spacer } from "../../components/Layout/Layout";
import ChangeSubscriptionModal from "../../components/Object/ChangeSubscriptionModal";
import MoveObjectModal from "../../components/Object/MoveObjectModal";
import { BaseTableProps } from "../../core/api/common_table";
import {
  bulkCancelSubscription,
  deleteObject,
  patchUpdateObject,
  pushConfiguration,
} from "../../core/api/objects/objects";
import { ObjectPatch } from "../../core/api/objects/types";
import DateTimeHelper from "../../core/helpers/dateTimeHelper";
import {
  notifyApiErrors,
  sortParamsToString,
} from "../../core/helpers/helpers";
import useTranslations from "../../core/i18n/useTranslations";
import useUser from "../../core/user/useUser";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import Badge from "../../ui-lib/components/Badges/Badge";
import Button from "../../ui-lib/components/Button/Button";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { IDropdownItem } from "../../ui-lib/components/Dropdown/DropdownItem";
import NoItems from "../../ui-lib/components/Tables/NoItems";
import Table, { TableColumn } from "../../ui-lib/components/Tables/Table";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import {
  TableRowSelectionPopup,
  TableRowSelectionPopupActions,
} from "../../ui-lib/components/Tables/TableRowSelectionPopup";
import styles from "../Layout.module.css";
import { BatchSMSModal } from "../../components/Object/BatchSMSModal";
import { isOrganizationVisible } from "../../ui-lib/utils/organizationsHelper";
import AllTableRowSelectionPopup, {
  AllTableRowSelectionPopupActions,
} from "../../ui-lib/components/Tables/AllTableRowSelectionPopup";
import { BatchInfoModal } from "./BatchInfoModal";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import { Role, RoleFeature } from "../../components/RoleFeature";

export interface RowData {
  [key: string]: string;
}

export const ObjectsTable = ({
  data,
  pageSettings,
  onPageSettingsChange,
  sortParams,
  onSortChange,
  selectedTableColumns,
  onClear,
  onDataChanged,
  isFiltersApplied,
  parentClearSelection,
  tableParams,
}: BaseTableProps) => {
  const history = useHistory();
  const user = useUser();
  const t = useTranslations();

  const viserPermission = user.config?.show.includes("SensioHideFields");
  const viserSabo = user.config?.show.includes("SensioHideFieldsSABO");

  const getColumnName = (colKey: string): string => {
    const splited = colKey.split(".");
    const languageKey = splited.length === 1 ? colKey : splited[1];

    return t(`Table:${languageKey}`);
  };
  const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
  const [smsModalOpen, setSmsModalOpen] = useState(false);
  const [moveModalOpen, setMoveModalOpen] = useState<boolean>(false);
  const [isSelectAllVisible, setIsSelectAllVisible] = useState(false);
  const [changeSubscriptionModalOpen, setChangeSubscriptionModalOpen] =
    useState<boolean>(false);
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const [selectedRowAction, setSelectedRowAction] = useState<number>();
  const [selectedConvertItem, setSelectedConvertItem] = useState<number>(0);
  const [batchInfoModalHeader, setBatchInfoModalHeader] = useState<
    string | undefined
  >();
  const [selectAllModalOpened, setSelectAllModalOpened] = useState(false);
  const [batchExportModalOpen, setBatchExportModalOpen] = useState(false);
  const selectedItemRows = useRef<RowData[]>([]);
  const clearSelectedFunction = useRef<Function>();
  const tableRowSelectionPopupRef =
    useRef<TableRowSelectionPopupActions<RowData>>(null);
  const allRowSelectionPopupRef =
    useRef<AllTableRowSelectionPopupActions<RowData>>(null);
  const rowSelectionActionItems: IDropdownItem[] = [
    {
      id: 1,
      name: t("Objects:dropdown_cancel_subscription"),
      isSelected: selectedRowAction === 1,
    },
    // {
    //   id: 2,
    //   name: t("Objects:dropdown_move_subscription_selected"),
    //   isSelected: selectedRowAction === 2,
    // },
    {
      id: 3,
      name: t("Objects:dropdown_move_organisation_selected"),
      isSelected: selectedRowAction === 3,
    },
    {
      id: 4,
      name: t("Objects:dropdown_export_selected"),
      isSelected: selectedRowAction === 4,
    },
    {
      id: 5,
      name: t("Objects:dropdown_convert_demo_selected"),
      isSelected: selectedRowAction === 5,
    },
    {
      id: 6,
      name: t("Objects:dropdown_push_configuration_selected"),
      isSelected: selectedRowAction === 6,
    },
    {
      id: 7,
      name: t("Objects:dropdown_batch_sms"),
      isSelected: selectedRowAction === 7,
    },
  ];
  if (user.config?.show.find((key) => key === "DeleteUser")) {
    rowSelectionActionItems.unshift({
      id: 0,
      name: t("Objects:dropdown_delete_selected"),
      isSelected: selectedRowAction === 0,
    });
  }
  const convertItems: IDropdownItem[] = [
    {
      id: 0,
      name: t("Objects:dropdown_convert_to_demo"),
      isSelected: selectedConvertItem === 0,
    },
    {
      id: 1,
      name: t("Objects:dropdown_convert_from_demo"),
      isSelected: selectedConvertItem === 1,
    },
  ];

  const setClearItemsFunction = (func: Function) => {
    clearSelectedFunction.current = func;
    if (parentClearSelection) {
      parentClearSelection.current = () => {
        clearSelectedFunction.current?.();
        tableRowSelectionPopupRef.current?.close(true);
        allRowSelectionPopupRef.current?.close(true);
        setIsSelectAllVisible(false);
        setSelectAllModalOpened(false);
      };
    }
  };
  const columns: TableColumn<RowData>[] = data.columns.map((colKey, i) => ({
    header: getColumnName(colKey),
    fieldTemplate: (rowData, rowIndex) => {
      const value = rowData[colKey];
      const fieldType = data.columnDataTypes[i];
      const showNoSubscriptionBlock = colKey === "ResponseCenterValidTo";
      let link: string | undefined;
      switch (colKey) {
        case "Name":
          // object ids contains only elements from current page
          // so we need to calculate real index
          link = `objects/${data.objectIds[rowIndex % pageSettings.pageSize]}`;
          break;
        case "ResponseCenter":
          link = `alarm-receptions/${
            data.responsecenterIds[rowIndex % pageSettings.pageSize]
          }`;
          break;
        case "Customer":
          if (isOrganizationVisible(user)) {
            link = `organizations/${
              data.organizationIds[rowIndex % pageSettings.pageSize]
            }`;
          }
          break;
        default:
          break;
      }

      if (colKey === "Active") {
        return (
          <Badge
            variant={value === "True" ? "success" : "error"}
            title={t(
              `Administrator:${value === "True" ? "active" : "inactive"}`
            )}
            withHighlightedText
          />
        );
      }

      switch (fieldType) {
        case "DATETIME": {
          const shortDateTime = value.split("T").length === 2;
          const valueToUse = shortDateTime
            ? DateTimeHelper.formatDateTimeString(value)
            : DateTimeHelper.formatDate(new Date(value));

          return value && value.length > 0 ? (
            <TableCell value={valueToUse} className={styles.shortDateCell} />
          ) : (
            <TableCell
              value={
                showNoSubscriptionBlock ? (
                  <Badge
                    variant="error"
                    title={t("Objects:title_nosubscription")}
                    withHighlightedText
                  />
                ) : (
                  ""
                )
              }
              className={styles.shortDateCell}
            />
          );
        }
        case "LONG":
        case "STRING":
        default:
          return <TableCell value={he.decode(value || "")} linkTo={link} />;
      }
    },
    sortByField: Object.values(data.sortableColumns).find(
      (value) => value === colKey
    ),
  }));

  const items: RowData[] = Object.values(data.rows).map((values, i) => {
    const rowData: RowData = {
      id: `${data.objectIds[i]}`,
    };

    data.columns.forEach((colKey, j) => {
      rowData[colKey] = values[j];
    });

    return rowData;
  });

  const viserSaboRowSelectedAction = rowSelectionActionItems.find(
    (i) => i.id === 4
  )!;

  useEffect(() => {
    if (!selectedRowAction) {
      setSelectedRowAction(
        viserSabo ? 4 : (rowSelectionActionItems[0].id as number)
      );
    }
  }, []);

  return (
    <>
      <TableRowSelectionPopup
        ref={tableRowSelectionPopupRef}
        renderBody={(selectedItems) => (
          <Row
            type="space"
            align="center"
            style={{ width: "100%", maxWidth: "900px", flexGrow: 1 }}
          >
            <Dropdown
              items={
                viserSabo
                  ? [viserSaboRowSelectedAction]
                  : rowSelectionActionItems
              }
              bodyWidth="fit-content"
              placeholder={t("Transmitters:select_action")}
              selectedItem={rowSelectionActionItems.find((r) => r.isSelected)}
              onSelectItem={(item) => {
                setSelectedRowAction(+item.id);
              }}
              disabled={viserSabo}
              width="230px"
              maxVisible={6}
            />
            {selectedRowAction === 0 && (
              <>
                <BulkDeleteModal
                  isOpen={deletionModalOpen}
                  onClose={() => {
                    setDeletionModalOpen(false);
                  }}
                  onDelete={() => {
                    setDeletionModalOpen(false);
                    clearSelectedFunction.current?.();
                    tableRowSelectionPopupRef.current?.close(true);
                    if (onDataChanged) {
                      setTimeout(() => {
                        onDataChanged?.();
                      }, 500);
                    }
                  }}
                  ids={
                    selectedItemRows.current?.map((s) => s.id?.toString()) || []
                  }
                  labels={{
                    single: t("Objects:type"),
                    multi: t("Objects:type_multi"),
                  }}
                  apiFunc={deleteObject}
                />
                <Button
                  variant="destructive"
                  text={`${t("Objects:delete_selection")} (${
                    selectedItems.length
                  })`}
                  onClick={() => {
                    selectedItemRows.current = selectedItems;
                    setDeletionModalOpen(true);
                  }}
                />
              </>
            )}
            {selectedRowAction === 1 && (
              <Button
                variant="primary"
                text={`${t("Objects:apply_to_selection")} (${
                  selectedItems.length
                })`}
                loading={isWorking}
                onClick={async () => {
                  let success = true;
                  setIsWorking(true);
                  const ids = selectedItems.map((sr) => +sr.id);

                  try {
                    await bulkCancelSubscription(
                      ids,
                      user.authenticatedRequest
                    );
                  } catch (error: any) {
                    success = false;
                    if (error.response.data.Message) {
                      notify({
                        message: t(error.response.data.Message),
                      });
                    } else {
                      notifyApiErrors(
                        error.response?.data?.errors,
                        error.response?.status
                      );
                    }
                  }
                  if (success) {
                    notify({
                      message: t("Objects:change_subscription_valid_success"),
                    });
                    clearSelectedFunction.current?.();
                    tableRowSelectionPopupRef.current?.close(true);
                    onDataChanged?.();
                  }

                  setIsWorking(false);
                }}
              />
            )}
            {selectedRowAction === 2 && (
              <>
                <ChangeSubscriptionModal
                  isOpen={changeSubscriptionModalOpen}
                  onSuccess={() => {
                    clearSelectedFunction.current?.();
                    tableRowSelectionPopupRef.current?.close(true);
                    onDataChanged?.();
                  }}
                  onClose={() => {
                    setChangeSubscriptionModalOpen(false);
                  }}
                  // name={t("Objects:change_organisation_description")}
                  modalTitle={t("Objects:change_organisation")}
                  text={t("Objects:change_organisation_description")}
                  objects={selectedItemRows.current}
                  responseCenterId={
                    +selectedItemRows.current?.[0].SubscriptionId
                  }
                />
                <Button
                  variant="primary"
                  text={`${t("Objects:change_selection")} (${
                    selectedItems.length
                  })`}
                  onClick={() => {
                    selectedItemRows.current = selectedItems;
                    setChangeSubscriptionModalOpen(true);
                  }}
                />
              </>
            )}
            {selectedRowAction === 3 && (
              <>
                <MoveObjectModal
                  isOpen={moveModalOpen}
                  onSuccess={() => {
                    clearSelectedFunction.current?.();
                    tableRowSelectionPopupRef.current?.close(true);
                    onDataChanged?.();
                  }}
                  onPartialSuccess={() => {
                    onDataChanged?.();
                  }}
                  onClose={() => {
                    setMoveModalOpen(false);
                  }}
                  modalTitle={t("Objects:change_organisation")}
                  text={t("Objects:change_organisation_description")}
                  objects={selectedItemRows.current}
                />
                <Button
                  variant="primary"
                  text={`${t("Objects:change_selection")} (${
                    selectedItems.length
                  })`}
                  onClick={() => {
                    selectedItemRows.current = selectedItems;
                    setMoveModalOpen(true);
                  }}
                />
              </>
            )}
            {selectedRowAction === 4 && (
              <>
                <Button
                  variant="primary"
                  text={`${t("Common:exportselection")} (${
                    selectedItems.length
                  })`}
                  onClick={() => setBatchExportModalOpen(true)}
                />
                <PrimeModal
                  isOpen={batchExportModalOpen}
                  onClose={() => setBatchExportModalOpen(false)}
                >
                  <ExportObjectsSettings
                    labels={{
                      title: t("Objects:export"),
                      description: `${selectedItems.length} ${t(
                        "Objects:selected"
                      )}`,
                      submit: `${t("Common:exportselection")} (${
                        selectedItems.length
                      })`,
                    }}
                    tableParams={{
                      sort: sortParamsToString(sortParams),
                    }}
                    onClose={() => setBatchExportModalOpen(false)}
                    objectIds={selectedItems.map(
                      (i) => i.id as unknown as number
                    )}
                    columnInfo={selectedTableColumns?.columns}
                  />
                </PrimeModal>
              </>
            )}
            {selectedRowAction === 5 && (
              <>
                <Dropdown
                  items={convertItems}
                  placeholder={t("Transmitters:select_action")}
                  selectedItem={convertItems.find((r) => r.isSelected)}
                  onSelectItem={(item) => {
                    setSelectedConvertItem(+item.id);
                  }}
                  width="180px"
                  bodyWidth={230}
                />
                <Button
                  variant="primary"
                  text={`${t("Objects:convert_selection")} (${
                    selectedItems.length
                  })`}
                  loading={isWorking}
                  onClick={async () => {
                    let success = true;
                    setIsWorking(true);
                    const Ids = selectedItems.map((sr) => sr.id);
                    const object: ObjectPatch = {
                      demoAccount: selectedConvertItem === 0,
                    };
                    for (const id of Ids) {
                      try {
                        await patchUpdateObject(
                          id,
                          object,
                          user.authenticatedRequest
                        );
                      } catch (error: any) {
                        success = false;
                        if (error.response.data.Message) {
                          notify({
                            message: t(error.response.data.Message),
                          });
                        } else {
                          notifyApiErrors(
                            error.response?.data?.errors,
                            error.response?.status
                          );
                        }
                      }
                    }
                    if (success) {
                      notify({
                        message: t("Objects:convert_subscription_success"),
                      });
                      clearSelectedFunction.current?.();
                      tableRowSelectionPopupRef.current?.close(true);
                      onDataChanged?.();
                    }

                    setIsWorking(false);
                  }}
                />
              </>
            )}
            {selectedRowAction === 6 && (
              <Button
                variant="primary"
                text={`${t("Objects:configure_selection")} (${
                  selectedItems.length
                })`}
                loading={isWorking}
                onClick={async () => {
                  let success = true;
                  setIsWorking(true);
                  const Ids = selectedItems.map((sr) => sr.id);

                  // eslint-disable-next-line no-restricted-syntax
                  for (const id of Ids) {
                    try {
                      // eslint-disable-next-line no-await-in-loop
                      await pushConfiguration(id, user.authenticatedRequest);
                    } catch (error: any) {
                      success = false;
                      if (error.response.data.Message) {
                        notify({
                          message: t(error.response.data.Message),
                        });
                      } else {
                        notifyApiErrors(
                          error.response?.data?.errors,
                          error.response?.status
                        );
                      }
                    }
                  }
                  if (success) {
                    notify({
                      message: t("Objects:configure_success"),
                    });
                    clearSelectedFunction.current?.();
                    tableRowSelectionPopupRef.current?.close(true);
                    onDataChanged?.();
                  }

                  setIsWorking(false);
                }}
              />
            )}
            {selectedRowAction === 7 && (
              <>
                <BatchSMSModal
                  isOpen={smsModalOpen}
                  onClose={() => {
                    setSmsModalOpen(false);
                  }}
                  ids={
                    selectedItemRows.current?.map((s) => s.id?.toString()) || []
                  }
                  tableData={items}
                />
                <Button
                  variant="primary"
                  text={`${t("Objects:apply_to_selection")} (${
                    selectedItems.length
                  })`}
                  onClick={() => {
                    selectedItemRows.current = selectedItems;
                    setSmsModalOpen(true);
                  }}
                />
              </>
            )}
          </Row>
        )}
      />
      <RoleFeature type="oneOfNot" requires={[Role.ViserStandart]}>
        <AllTableRowSelectionPopup
          ref={allRowSelectionPopupRef as any}
          onClose={(headerText?: any) => {
            setIsSelectAllVisible(false);
            clearSelectedFunction.current?.();
            allRowSelectionPopupRef.current?.close(true);
            tableRowSelectionPopupRef.current?.close(true);
            if (typeof headerText === "string") {
              setBatchInfoModalHeader(headerText);
            }
            setSelectAllModalOpened(false);
          }}
          tableParams={tableParams!}
        />
        <BatchInfoModal
          isOpen={!!batchInfoModalHeader}
          onClose={() => {
            setBatchInfoModalHeader(undefined);
          }}
          headerText={batchInfoModalHeader}
        />
        {isSelectAllVisible && (
          <>
            <Spacer size={8} />
            <div className={styles.selectAllWrapper}>
              <span>
                {t("Objects:n_objects_selected").replace(
                  "{0}",
                  `${selectAllModalOpened ? data.hits : data.objectIds.length}`
                )}
              </span>
              {!selectAllModalOpened && (
                <>
                  <Spacer size={16} />
                  <Button
                    customStyles={{ height: "32px" }}
                    variant="secondary"
                    text={t("Objects:select_all").replace(
                      "{0}",
                      `${data.hits}`
                    )}
                    onClick={() => {
                      allRowSelectionPopupRef.current?.open(data.hits);
                      tableRowSelectionPopupRef.current?.close(false);
                      setSelectAllModalOpened(true);
                    }}
                  />
                </>
              )}
            </div>
            <Spacer size={8} />
          </>
        )}
      </RoleFeature>
      {items.length > 0 ? (
        <Table<RowData>
          tableName="table-objects"
          columns={columns}
          setClearItemsFunction={setClearItemsFunction}
          hideEmptyMessage
          items={items}
          withRowSelection={viserSabo || !viserPermission}
          showRowHover
          withPagination
          withLazyLoading
          paginatedItems={{
            items,
            pagination: {
              offset: pageSettings.pageSize * (pageSettings.page - 1),
              limit: pageSettings.pageSize,
              total: data.hits,
            },
          }}
          onPageChange={(nextPage) => {
            const page = Math.floor(nextPage.offset / nextPage.limit);

            if (!Number.isNaN(page) && nextPage.limit) {
              onPageSettingsChange({
                page: page + 1,
                pageSize: nextPage.limit,
              });
            }
            clearSelectedFunction.current?.();
          }}
          sortParams={sortParams}
          onSortChange={(sortData) => {
            clearSelectedFunction.current?.();
            onSortChange?.(sortData);
            onPageSettingsChange({
              page: 1,
              pageSize: pageSettings.pageSize,
            });
          }}
          rowActions={[
            {
              icon: "pencil-alt",
              text: t("Common:label_edit"),
              onClick: (rowData) => history.push(`objects/${rowData.id}`),
            },
            {
              icon: "status-online",
              text: t("Common:view_alarm_history"),
              onClick: ({ id }) =>
                history.push(`/adminportal/objects/${id}/alarm-history`),
            },
          ]}
          onRowSelectionChange={(selectedItems) => {
            if (!selectedItems.length) {
              setIsSelectAllVisible(false);
              allRowSelectionPopupRef.current?.close(true);
            }
            setIsSelectAllVisible(
              selectedItems.length === data.objectIds.length
            );
            if (selectedItems.length === data.objectIds.length) {
              setSelectAllModalOpened(false);
            }
            tableRowSelectionPopupRef.current?.open(selectedItems);
          }}
          onAllRowsSelect={(selectedItems) => {
            tableRowSelectionPopupRef.current?.open(selectedItems);
            setIsSelectAllVisible(true);
            setSelectAllModalOpened(false);
          }}
          rowActionsFixed
          rowActionsColWidth={64}
        />
      ) : (
        <NoItems
          title={
            !isFiltersApplied
              ? t("Table:noresult_title")
              : t("Table:no_results_found")
          }
          icon={!isFiltersApplied ? "eye-off" : undefined}
          subTitle={
            !isFiltersApplied
              ? t("Table:noresult_subtitle")
              : t("Table:adjust_filter_description")
          }
          clear={
            isFiltersApplied && onClear
              ? {
                  text: t("Table:clear_filters"),
                  onClick: onClear,
                }
              : undefined
          }
        />
      )}
    </>
  );
};

import { Controller, useForm } from "react-hook-form";
import { Dispatch, forwardRef, useImperativeHandle, useState } from "react";
import { Link } from "react-router-dom";
import { usePlacesWidget } from "react-google-autocomplete";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import {
  decodeContactDetails,
  encodeContactDetails,
} from "../../../../core/api/contacts/helpers";
import { ContactDetails } from "../../../../core/api/contacts/types";
import { ScheduleItem } from "../../../../ui-lib/components/Scheduler/ScheduleItem";
import useTranslations from "../../../../core/i18n/useTranslations";
import Alert from "../../../../ui-lib/components/Alerts/Alert";
import Button from "../../../../ui-lib/components/Button/Button";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import Tree, { ITreeData } from "../../../../ui-lib/components/Hierarchy/Tree";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import RadioGroup from "../../../../ui-lib/components/Radio/RadioGroup";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../../core/SaveObjectContext/hooks";
import Icon from "../../../../ui-lib/components/Icon";
import Divider from "../../../../ui-lib/components/Divider/Divider";
import useUser from "../../../../core/user/useUser";
import useSharedContact from "../../../CommonContacts/SharedContactContext/useSharedContact";
import ContactInfoForm from "./ContactInfoForm";
import TimePicker from "../../../../ui-lib/components/DateAndTime/TimePicker";
import useLanguage from "../../../../core/i18n/useLanguage";
import TextArea from "../../../../ui-lib/components/Inputs/Textarea";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";

const displayInCentralOptions = [0, 1, 2];

const callMethodOptions = [99, 0, 1];

export declare type ContactFormActions = {
  submitForm: any;
};

export interface ContactFormProps {
  onSubmit: (values: ContactDetails) => Promise<void>;
  defaultValues?: ContactDetails;
  organisations?: ITreeData[];
  setCreateLogin?: Dispatch<boolean>;
  isModal?: boolean;
  hideTime?: boolean;
  withNewSchedule?: boolean;
  privateContact?: boolean;
}

export const ContactForm = forwardRef<ContactFormActions, ContactFormProps>(
  (
    {
      defaultValues,
      onSubmit,
      organisations,
      setCreateLogin,
      isModal = true,
      hideTime = false,
      withNewSchedule = false,
      privateContact = false,
    }: ContactFormProps,
    ref
  ) => {
    const t = useTranslations();
    const user = useUser();
    const { currentLanguage } = useLanguage();

    const {
      setNewData,
      setShowChangeContactModal,
      updateContact,
      showChangeContactModal,
      newData,
      isLoading,
    } = useSharedContact();

    const hasShowOnlyBasicContactInfoPermission = user.config?.show.includes(
      "ShowOnlyBasicContactInfo"
    );
    const hasHideAddObjectCommonContactPermission = user.config?.show.includes(
      "HideAddObjectCommonContact"
    );

    const contactTypeOptions = [
      {
        id: "contact_person",
        isResource: false,
      },
      ...(!hasHideAddObjectCommonContactPermission
        ? [
            {
              id: "resource",
              isResource: true,
            },
          ]
        : []),
    ];

    const {
      control,
      formState: { errors },
      watch,
      setValue,
      getValues,
      handleSubmit,
      trigger,
      reset,
      clearErrors,
    } = (isModal ? useForm : useObjectForm)<ContactDetails>({
      mode: "onChange",
      defaultValues: defaultValues
        ? encodeContactDetails(defaultValues)
        : {
            globalType: 0,
            isResource: false,
            sendResponseAlarm: false,
            hideFromResponseCenter: false,
            callMethod: 99,
          },
    });
    const formRef = useObjectFormRef();
    const isResource = watch("isResource");
    const callMethod = watch("callMethod");
    const [showNewScheduler, setShowNewScheduler] = useState(false);

    const { ref: placeRef } = usePlacesWidget<HTMLInputElement>({
      apiKey: "AIzaSyAOhQYmEXHT3jNB8dOHsKT1W26B5llY_UI",
      onPlaceSelected: (place) => {
        const value = placeRef.current?.value;
        const lat = place?.geometry?.location?.lat();
        const lng = place?.geometry?.location?.lng();
        setValue("address", value ?? "");
        setValue("latitude", lat);
        setValue("longitude", lng);
      },
      options: {
        fields: [
          "address_components",
          "geometry",
          "icon",
          "name",
          "place_id",
          "formatted_address",
        ],
        strictBounds: false,
        types: [],
      },
      language: currentLanguage?.nativeName || user.config?.primaryLanguage,
    });

    const validateOrganisation = () => {
      const canSetOrganisation = !!organisations;

      if (!canSetOrganisation) {
        return true;
      }

      return !!watch("organizationId");
    };

    const internalSubmit = async (): Promise<any> => {
      try {
        handleSubmit(async (values: ContactDetails) => {
          const decoded = decodeContactDetails(values);

          // Global type is not relevant for contacts of type resource
          if (decoded.isResource) {
            decoded.globalType = 0;
          }
          setNewData(decoded);
          await onSubmit(decoded);
          reset(values);
        })();
      } catch (error: any) {
        notifyApiErrors(error.response?.data?.errors);
      }
    };

    useImperativeHandle<ContactFormActions, ContactFormActions>(ref, () => ({
      submitForm: internalSubmit,
    }));

    const renderGlobalType = () => (
      <Controller
        name="globalType"
        control={control}
        render={({ field }) => {
          const dropdownItems = displayInCentralOptions.map((id) => ({
            id,
            name: t(`Contacts:input_display_in_central_${id}`),
            isSelected: isResource ? id === 0 : id === field.value,
          }));

          if (isResource || privateContact) {
            return <></>;
          }

          return (
            <Dropdown
              title={t("Contacts:input_display_in_central_label")}
              width="376px"
              selectedItem={dropdownItems.find((i) => i.isSelected)}
              items={dropdownItems}
              onSelectItem={(item) => setValue(field.name, item.id as number)}
            />
          );
        }}
      />
    );

    const renderDescription = () => (
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextInput
            label={
              privateContact
                ? t("Objects:labels_contact_relation")
                : t("Objects:general_relation")
            }
            placeholder={t("Objects:placeholder_contact_relation")}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    );

    return (
      <>
        <PrimeModal
          withFooter
          withHeader
          contentStyle={{ minWidth: "576px" }}
          header={t("Contacts:change_contact_resource")}
          isOpen={showChangeContactModal}
          loading={isLoading}
          submitBtn={{
            text: t("Contacts:yes_continue"),
            variant: "destructive",
            onClick: () => {
              updateContact(newData!);
            },
          }}
          cancelBtn={{
            text: t("Common:cancel"),
            onClick: () => {
              setShowChangeContactModal(false);
            },
          }}
          onClose={() => {
            setShowChangeContactModal(false);
            reset(defaultValues);
          }}
        >
          <p>
            {!newData?.isResource
              ? t("Contacts:change_contact_resource_type_description").replace(
                  "{}",
                  defaultValues?.email!
                )
              : t(
                  "Contacts:change_contact_resource_call_method_description"
                ).replace("{}", defaultValues?.email!)}
          </p>
        </PrimeModal>
        <form
          ref={formRef}
          id="contact_form"
          style={{ width: "100%" }}
          onSubmit={!isModal ? internalSubmit : undefined}
        >
          <Controller
            name="isResource"
            control={control}
            render={({ field }) => {
              const radioGroupItems = contactTypeOptions.map((o, i) => ({
                id: i,
                value: o.id,
                text: t(`Contacts:input_${o.id}`),
                description:
                  o.id !== "contact_person" ||
                  !user.config?.show.includes("ShowObjectDetailsVariant1")
                    ? t(`Contacts:input_${o.id}_description`)
                    : "",
              }));

              return (
                <RadioGroup
                  label={t("Contacts:input_contact_type_label")}
                  name={field.name}
                  items={radioGroupItems}
                  selectedValue={
                    contactTypeOptions.find((o) => o.isResource === field.value)
                      ?.id
                  }
                  onChange={(value) => {
                    const isResorce = !!contactTypeOptions.find(
                      (o) => o.id === value
                    )?.isResource;

                    setValue(field.name, isResorce);
                  }}
                />
              );
            }}
          />

          <Spacer size={16} />

          <Row align="start">
            <Column>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextInput
                    required
                    inputRef={field.ref}
                    label={t("Contacts:contact_name")}
                    placeholder={t("Common:placeholder_name")}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors.name && t("Errors:input_field_required")
                    }
                  />
                )}
              />
            </Column>

            <Spacer size={32} />

            <Column>
              {!organisations ? (
                renderDescription()
              ) : (
                <>
                  {!defaultValues?.userId ? (
                    <Controller
                      name="organizationId"
                      control={control}
                      rules={{
                        validate: validateOrganisation,
                      }}
                      render={({ field }) => (
                        <Tree
                          treeRef={field.ref}
                          label={t("Common:belongs_to")}
                          placeholder={t("Common:organisation")}
                          items={organisations}
                          selectedTreeItem={field.value}
                          onSelectItem={(data?: ITreeData) => {
                            const organizationId = data?.key as
                              | number
                              | undefined;
                            setValue(field.name, organizationId);
                            trigger(field.name); // Not sure why this is needed here, but if we remove it, any errors messages will not get cleared as expected
                          }}
                          validationError={
                            errors.organizationId &&
                            t("Errors:input_field_required")
                          }
                        />
                      )}
                    />
                  ) : (
                    renderDescription()
                  )}
                </>
              )}
            </Column>
          </Row>

          <Spacer size={16} />

          {(!isResource || organisations) &&
            !defaultValues?.userId &&
            !hasShowOnlyBasicContactInfoPermission && (
              <>
                <Row align="start">
                  <Column align="start">
                    {organisations ? renderDescription() : renderGlobalType()}
                  </Column>

                  {!privateContact && (
                    <>
                      <Spacer size={32} />

                      <Column>{renderGlobalType()}</Column>
                    </>
                  )}
                </Row>

                <Spacer size={16} />
              </>
            )}

          {!isResource &&
            defaultValues?.userId &&
            !hasShowOnlyBasicContactInfoPermission && (
              <>
                <Row align="start">
                  <Column align="start">{renderGlobalType()}</Column>
                </Row>
                <Spacer size={16} />
              </>
            )}

          {isResource && (
            <>
              <Row align="start">
                <Column style={{ width: "100%" }}>
                  <Controller
                    name="callMethod"
                    control={control}
                    render={({ field }) => {
                      const dropdownItems = callMethodOptions.map((id) => ({
                        id,
                        name: t(`Contacts:input_call_method_${id}`),
                        isSelected: id === field.value,
                      }));

                      return (
                        <Dropdown
                          title={t("Contacts:input_call_method")}
                          width="100%"
                          selectedItem={dropdownItems.find((i) => i.isSelected)}
                          items={dropdownItems}
                          onSelectItem={(item) => {
                            setCreateLogin?.(
                              item.id === 1 && !defaultValues?.adminId
                            );
                            setValue(field.name, item.id as number);
                          }}
                        />
                      );
                    }}
                  />
                </Column>
                <Spacer size={32} />
                <Column style={{ width: "100%" }}>
                  {callMethod === 1 && setCreateLogin && (
                    <div style={{ width: "100%", marginTop: "24px" }}>
                      <Checkbox
                        label={t("Contacts:create_logn")}
                        checked
                        disabled
                        style={{ padding: 0 }}
                      />
                    </div>
                  )}
                </Column>
              </Row>

              {defaultValues?.adminId && callMethod === 1 && (
                <>
                  <Spacer size={16} />
                  <Row type="left" align="center">
                    <Link
                      to={`/adminportal/administrators/${defaultValues?.adminId}`}
                      style={{ fontWeight: 600, fontSize: "14px" }}
                    >
                      {t("Contacts:edit_linked_resource")}
                    </Link>
                    <Icon
                      name="chevron-right"
                      size={14}
                      color="Primary-700"
                      margin="3px 0 0"
                    />
                  </Row>
                  <Spacer size={16} />
                  <Divider />
                </>
              )}

              <Spacer size={16} />
            </>
          )}

          {getValues("callMethod") !== 1 && (
            <>
              <Alert
                title={t("Errors:input_contacts_required_fields")}
                variant="info"
                withDefaultIcon
              />

              <Spacer size={16} />
            </>
          )}

          <ContactInfoForm
            control={control}
            errors={errors}
            hasShowOnlyBasicContactInfoPermission={
              hasShowOnlyBasicContactInfoPermission
            }
            watch={watch}
            getValues={getValues}
            setValue={setValue}
            trigger={trigger}
          />

          {!hasShowOnlyBasicContactInfoPermission && (
            <>
              <Spacer size={16} />

              <Row>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={t("Common:label_address")}
                      placeholder={t("Common:placeholder_address")}
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={placeRef}
                      // eslint-disable-next-line consistent-return
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          return false;
                        }
                      }}
                    />
                  )}
                />
                <Spacer size={32} />
                <Controller
                  name="billingReference"
                  control={control}
                  rules={{ maxLength: 45 }}
                  render={({ field }) => (
                    <TextInput
                      inputRef={field.ref}
                      label={t("Common:billing_reference")}
                      placeholder={t("Common:billing_placeholder")}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors?.billingReference &&
                        t("Errors:max_chars_exceeded").replace("{0}", "45")
                      }
                    />
                  )}
                />
              </Row>
            </>
          )}

          {!user.config?.show.includes("ShowObjectDetailsVariant1") && (
            <>
              <Spacer size={16} />

              <Row align="start">
                <Controller
                  name="notes"
                  control={control}
                  rules={{ maxLength: 400 }}
                  render={({ field }) => (
                    <TextArea
                      inputRef={field.ref}
                      label={t("Common:labels_additional_info")}
                      value={field.value}
                      onChange={field.onChange}
                      validationError={
                        errors?.notes &&
                        t("Errors:max_chars_exceeded").replace("{0}", "400")
                      }
                    />
                  )}
                />
              </Row>
            </>
          )}

          {withNewSchedule && (
            <>
              <Spacer size={16} />

              {showNewScheduler ? (
                <>
                  <h2>{t("Menu:availability")}</h2>
                  <Spacer size={8} />
                  <p>{t("Contacts:availability_description")}</p>
                  <Spacer size={16} />
                  <ScheduleItem
                    dateFormat={user.dateFormat!}
                    skipViewMode
                    partOfForm
                    control={control}
                    errors={errors}
                    watch={watch}
                    setValue={setValue}
                    getValues={getValues}
                    trigger={trigger}
                    onItemRemove={() => {
                      setShowNewScheduler(false);
                      setValue("scheduleItem", undefined);
                    }}
                    clearErrors={clearErrors}
                  />
                  <Spacer size={16} />
                  <Alert
                    title={t("Contacts:more_on_edit")}
                    variant="info"
                    icon="information-circle"
                  />
                </>
              ) : (
                <Button
                  image="plus"
                  variant="border-link"
                  text={t("Contacts:set_availability")}
                  onClick={() => {
                    setShowNewScheduler(true);
                    setValue("scheduleItem.repeatDays", [0, 1, 2, 3, 4, 5, 6]);
                    setValue("scheduleItem.repeatType", "RepeatDays");
                  }}
                />
              )}
            </>
          )}

          {!(hideTime || withNewSchedule) &&
            !hasShowOnlyBasicContactInfoPermission && (
              <>
                <Spacer size={16} />

                <Row align="start">
                  <Controller
                    name="startTime"
                    control={control}
                    render={({ field }) => (
                      <TimePicker
                        label={t("Contacts:input_start_time")}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Spacer size={32} />
                  <Controller
                    name="endTime"
                    control={control}
                    render={({ field }) => (
                      <TimePicker
                        label={t("Contacts:input_end_time")}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </Row>
              </>
            )}

          {!hasShowOnlyBasicContactInfoPermission && (
            <>
              <Spacer size={16} />

              <Row align="start">
                <Controller
                  name="sendResponseAlarm"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      label={t("Contacts:input_send_info")}
                      checked={field.value}
                      onChange={field.onChange}
                      style={{ padding: 0, width: "100%" }}
                    />
                  )}
                />
                <Spacer size={32} />
                <Controller
                  name="hideFromResponseCenter"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      label={t("Contacts:input_hide")}
                      checked={field.value}
                      onChange={field.onChange}
                      style={{ padding: 0, width: "100%" }}
                    />
                  )}
                />
              </Row>
            </>
          )}
        </form>
      </>
    );
  }
);

import classNames from "classnames";
import { Checkbox as Ch } from "primereact/checkbox";
import styles from "./Checkbox.module.css";
import { useTranslation } from "react-i18next";

export interface ICheckbox {
  id: string | number;
  label: string;
  checked: boolean;
  description?: string;
  disabled?: boolean;
}

export interface CheckboxProps {
  checked: boolean;
  onChange?(arg0: any): void;
  label?: string;
  disabled?: boolean;
  description?: string;
  fullWidth?: boolean;
  isInsideDropdown?: boolean;
  focused?: boolean;
  style?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  id?: string;
  htmlFor?: string;
  labelStyles?: React.CSSProperties;
  dataCy?: string;
  tooltip?: boolean;
}

export default function Checkbox({
  checked,
  onChange = () => {},
  label,
  disabled,
  description,
  fullWidth,
  isInsideDropdown = false,
  style,
  focused,
  innerStyle,
  id,
  htmlFor,
  labelStyles,
  dataCy,
  tooltip,
}: Readonly<CheckboxProps>) {
  const { t } = useTranslation();
  const itemClasses = classNames({
    [styles.checkbox]: true,
    [styles.focused]: focused,
  });

  return (
    <div className={`${itemClasses} option`} style={style} data-cy={dataCy}>
      <div className={styles.mainInfo} style={{ ...innerStyle }}>
        <Ch
          id={id}
          inputId={htmlFor ?? label}
          name={label}
          value={label}
          onChange={onChange}
          checked={checked}
          className={styles.checkboxContainer}
          disabled={disabled}
          tooltipOptions={{ position: "top" }}
          tooltip={tooltip ? t("Common:select_all_below") : undefined}
        />

        {label && (
          <label
            onChange={onChange}
            htmlFor={htmlFor ?? label}
            className={styles.checkboxLabel}
            style={{
              width: fullWidth ? "100%" : "auto",
              marginLeft: isInsideDropdown ? "8px" : "12px",
              ...labelStyles,
            }}
          >
            {label}
          </label>
        )}
      </div>
      {description && <div className={styles.description}>{description}</div>}
    </div>
  );
}

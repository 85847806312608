import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import moment from "moment";
import useTranslations from "../../../../core/i18n/useTranslations";
import {
  updateObjectAbsence,
  getAbsenceReason,
  getUserAbsenceType,
} from "../../../../core/api/objects/objects";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { Row, Spacer } from "../../../../components/Layout/Layout";
import DatePicker from "../../../../ui-lib/components/DateAndTime/DatePicker";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import Button from "../../../../ui-lib/components/Button/Button";
import { IDropdownItem } from "../../../../ui-lib/components/Dropdown/DropdownItem";
import useUser from "../../../../core/user/useUser";
import { IObjectAbsence } from "../../../../core/api/objects/types";
import { notifyApiErrors } from "../../../../core/helpers/helpers";
import { userDateFormat } from "../../../../ui-lib/utils/userDateFormat";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import { OverlapModal } from "./OverlapModal";

export const ScheduleAbsenceModal = ({
  defaultValues,
  onClose,
  adminId,
  reloadAbsence,
  isOngoing = false,
  ongoingAbsences = [],
}: {
  defaultValues?: IObjectAbsence;
  onClose: () => void;
  adminId: number;
  reloadAbsence: () => Promise<void>;
  isOngoing?: boolean;
  ongoingAbsences?: IObjectAbsence[];
}) => {
  const t = useTranslations();
  const { authenticatedRequest, dateFormat } = useUser();
  const [absenceReasons, setAbsenceReasons] = useState<IDropdownItem[]>();
  const [absenceDataToSubmit, setAbsenceDataToSubmit] =
    useState<IObjectAbsence>();
  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    trigger,
    getValues,
    setValue,
  } = useForm<IObjectAbsence>({
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          startDate: new Date(
            `${defaultValues.startDate} ${defaultValues.startTime}`
          ),
          endDate: defaultValues.endDate
            ? new Date(`${defaultValues.endDate} ${defaultValues.endTime}`)
            : undefined,
        }
      : {},
  });
  const isEdit = !!defaultValues;

  const [isLoading, setIsLoading] = useState(false);
  const [isEndDateVisible, setIsEndDateVisible] = useState(
    !!defaultValues?.endDate
  );

  const fetchReasons = async () => {
    try {
      const reasonTypeResult = await getUserAbsenceType(
        adminId,
        authenticatedRequest
      );
      const reasonResult = await getAbsenceReason(
        reasonTypeResult.data.reasonSetName,
        authenticatedRequest
      );

      const reasons = reasonResult.data.attributes.map((reason, index) => ({
        id: index,
        name: reason.name,
        isSelected: reason.name === defaultValues?.reason,
      }));
      setAbsenceReasons(reasons);
      setValue("reasonId", reasons.find((reason) => reason.isSelected)?.id);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReasons();
  }, []);

  if (isLoading || !absenceReasons) {
    return <LoadingSpinner theme="primary" />;
  }

  const checkForOverlappingAbsences = (data: IObjectAbsence) => {
    return ongoingAbsences.some((absence) => {
      const absenceStart = moment(absence.startDate);
      const absenceEnd = absence.endDate ? moment(absence.endDate) : null;
      const dataStart = moment(data.startDate);
      const dataEnd = data.endDate ? moment(data.endDate) : null;

      if (data.id === absence.id) return false;
      if (!absenceEnd) {
        return dataEnd
          ? dataEnd.isSame(absenceStart) || dataEnd.isAfter(absenceStart)
          : true;
      }

      if (!dataEnd) {
        return absenceEnd.isSame(dataStart) || absenceEnd.isAfter(dataStart);
      }

      return (
        dataStart.isBetween(absenceStart, absenceEnd) ||
        dataEnd.isBetween(absenceStart, absenceEnd) ||
        absenceStart.isBetween(dataStart, dataEnd) ||
        absenceEnd.isBetween(dataStart, dataEnd) ||
        dataStart.isSame(absenceStart) ||
        dataEnd.isSame(absenceEnd) ||
        dataEnd.isSame(absenceStart) ||
        dataStart.isSame(absenceEnd)
      );
    });
  };

  const onSubmit = async (data: IObjectAbsence) => {
    try {
      const { startDate } = data;
      data.startTime = moment(startDate).format("HH:mm");
      data.startDate = moment(startDate).format("YYYY-MM-DD");
      if (data.endDate) {
        const { endDate } = data;
        data.endTime = moment(endDate).format("HH:mm");
        data.endDate = moment(endDate).format("YYYY-MM-DD");
      }
      if (data.reasonId !== 999) {
        data.reason = absenceReasons.find(
          (reason) => reason.id === data.reasonId
        )?.name!;
      }
      delete data.reasonId;
      if (!checkForOverlappingAbsences(data)) {
        await updateObjectAbsence(adminId, data, authenticatedRequest);
        notify({
          message: isEdit
            ? t("Objects:absence_edit_success")
            : t("Objects:absence_scheduled_success"),
        });
        await reloadAbsence();
        onClose();
      } else {
        setAbsenceDataToSubmit(data);
      }
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  const validateEndDate = (value: Date) =>
    new Date(value).getTime() > new Date(getValues("startDate")).getTime();

  return (
    <>
      <OverlapModal
        isOpen={!!absenceDataToSubmit}
        onSubmit={async () => {
          await updateObjectAbsence(
            adminId,
            absenceDataToSubmit!,
            authenticatedRequest
          );
          notify({
            message: isEdit
              ? t("Objects:absence_edit_success")
              : t("Objects:absence_scheduled_success"),
          });
          setAbsenceDataToSubmit(undefined);
          await reloadAbsence();
          onClose();
        }}
        onClose={() => setAbsenceDataToSubmit(undefined)}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Objects:schedule_absence_description")}
        </p>
        <Spacer size={16} />

        <Controller
          name="startDate"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <DatePicker
              required
              dateFormat={userDateFormat(dateFormat!)}
              showTime
              onChange={(date) => {
                field.onChange(date);
                trigger("startDate");
              }}
              value={field.value}
              label={t("Objects:start_date_time")}
              style={{ width: "376px" }}
              disabled={isOngoing}
              validationError={
                errors?.startDate && t("Errors:input_field_required")
              }
            />
          )}
        />

        <Spacer size={16} />

        <Checkbox
          label={t("Objects:end_date_time")}
          onChange={() => {
            if (isEndDateVisible) {
              setValue("endDate", undefined);
              setValue("endTime", undefined);
            }
            setIsEndDateVisible(!isEndDateVisible);
          }}
          checked={isEndDateVisible}
          style={{
            padding: 0,
          }}
        />

        {isEndDateVisible && (
          <>
            <Spacer size={8} />
            <Controller
              name="endDate"
              control={control}
              rules={{
                validate: validateEndDate,
              }}
              render={({ field }) => (
                <DatePicker
                  dateFormat={userDateFormat(dateFormat!)}
                  showTime
                  onChange={(date) => {
                    field.onChange(date);
                    trigger("endDate");
                  }}
                  value={field.value}
                  label={t("Objects:end_date_time")}
                  style={{ width: "376px" }}
                  validationError={
                    errors?.endDate && t("Errors:start_greater_end_date")
                  }
                />
              )}
            />
          </>
        )}

        <Spacer size={16} />

        <Controller
          name="reasonId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Dropdown
              required
              selectedItem={absenceReasons.find((item) => item.isSelected)}
              onSelectItem={(item) => {
                field.onChange(item.id);
                setAbsenceReasons(
                  absenceReasons?.map((reason) => ({
                    ...reason,
                    isSelected: reason.id === item.id,
                  }))
                );
              }}
              items={absenceReasons}
              title={t("Objects:absence_reason")}
              validationError={
                errors?.reasonId && t("Errors:input_field_required")
              }
            />
          )}
        />

        <Spacer size={16} />

        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Objects:comment_text")}
              placeholder={t("Objects:comment_text_placeholder")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        {/* will be the part of the third release */}
        {/* <Spacer size={16} /> */}

        {/* <Controller */}
        {/*  name="createAlarm" */}
        {/*  control={control} */}
        {/*  render={({ field }) => ( */}
        {/*    <Checkbox */}
        {/*      label={t("Objects:create_alarm_after_absence")} */}
        {/*      onChange={field.onChange} */}
        {/*      checked={field.value} */}
        {/*      style={{ */}
        {/*        padding: 0, */}
        {/*      }} */}
        {/*    /> */}
        {/*  )} */}
        {/* /> */}

        <Spacer size={32} />

        <Row type="left">
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            text={
              isEdit ? t("Common:update") : t("Objects:schedule_the_absence")
            }
          />
          <Spacer size={8} />
          <Button
            variant="secondary"
            text={t("Common:cancel")}
            onClick={onClose}
            disabled={isSubmitting}
          />
        </Row>
        <Spacer size={32} />
      </form>
    </>
  );
};

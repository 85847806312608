import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import moment from "moment";
import { Spacer } from "../../../components/Layout/Layout";
import {
  IObjectAbsence,
  ObjectDetailsModel,
} from "../../../core/api/objects/types";
import { useOrganizationsTree } from "../../../core/api/organizations/organizations";
import { useObjectThumbnail } from "../../../core/api/photo";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import useTranslations from "../../../core/i18n/useTranslations";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import { ObjectGeneralForm } from "./ObjectGeneralForm";
import { useAlarmHandlings } from "../../../core/api/alarmhandlings";
import useUser from "../../../core/user/useUser";
import Alert from "../../../ui-lib/components/Alerts/Alert";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";
import { userDateFormat } from "../../../ui-lib/utils/userDateFormat";
import { ManualSyncWrapper } from "../../../components/ManualSync/ManualSyncWrapper";

import styles from "../../Layout.module.css";

const AbsenceAlert = ({
  data,
  isOngoing,
  dateFormat,
}: {
  data: IObjectAbsence;
  isOngoing: boolean;
  dateFormat: string;
}) => {
  const t = useTranslations();

  return (
    <>
      <div>
        <span className="fw-600">{`${t(
          `Objects:${isOngoing ? "absent" : "absent_upcoming"}`
        )} -`}</span>
        <span style={{ lineBreak: "anywhere" }}>{` ${data.reason}. `}</span>
        <span className="fw-600">{t("Objects:from")}</span>:{" "}
        <span>{`${moment(data.startDate).format(userDateFormat(dateFormat))} ${
          data.startTime
        } `}</span>
        {data.endDate ? (
          <>
            <span className="fw-600">{` ${t("Objects:to")}: `}</span>
            <span>{`${moment(data.endDate).format(
              userDateFormat(dateFormat)
            )} ${data.endTime}`}</span>
          </>
        ) : null}
      </div>
      {data.comment && (
        <div>
          <span className="fw-600">{t("Common:labels_additional_info")}</span>:{" "}
          <span>{data.comment}</span>
        </div>
      )}
    </>
  );
};

const ObjectGeneralContainer = ({ objectId }: { objectId: string }) => {
  const t = useTranslations();
  const history = useHistory();
  const { config } = useUser();

  const viserPermission = config?.show.includes("SensioHideFields");
  const viserHTJ = config?.show.includes("SensioHideFieldsOBO");

  if (viserPermission && !viserHTJ) {
    history.replace(`/adminportal/objects/${objectId}/accessories`);
  }

  const { authenticatedRequest, dateFormat } = useUser();

  const { data, absenceData, update, fetchObject } = useSharedObject();

  const { data: alarmHandlings = [] } = data?.active
    ? useAlarmHandlings(objectId)
    : {
        data: [],
      };

  const [thumbnail, setThumbnail] = useState("");

  const fetchThumbnail = async () => {
    try {
      const objectThumbnail = await useObjectThumbnail(
        objectId,
        authenticatedRequest
      );
      setThumbnail(objectThumbnail.data);
    } catch (error: any) {
      if (error.response.status === 404) {
        return console.log("Object image not found");
      }
      console.log("error fetching thimbnail");
    }
  };

  useEffect(() => {
    fetchThumbnail();
  }, []);

  const { data: organisationTree = [] } = useOrganizationsTree();
  if (!data || !alarmHandlings) {
    return null;
  }
  const onSubmit = async (values: ObjectDetailsModel) => {
    try {
      await update(values);

      notify({
        message: t("Objects:edited_success"),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const renderAbsence = () => {
    const upcomingAbsenceToRender = absenceData?.upcoming[0]
      ? [absenceData.upcoming[0]]
      : [];
    const absences = absenceData?.ongoing.length
      ? absenceData.ongoing
      : upcomingAbsenceToRender;

    const isOngoing = !!absenceData?.ongoing?.length;
    if (!absences) {
      return null;
    }
    return (
      <>
        {absences.map((absence, index) => (
          <>
            {index > 0 && <Spacer size={8} />}
            <Alert
              key={absence?.id}
              style={{ width: "722px" }}
              variant={isOngoing ? "warning" : "info"}
              title={
                <AbsenceAlert
                  data={absence}
                  isOngoing={isOngoing}
                  dateFormat={dateFormat!}
                />
              }
              withDefaultIcon
            />
          </>
        ))}

        <Spacer size={32} />
      </>
    );
  };

  return (
    <>
      <h1>{t("Objects:general_page_title")}</h1>
      <Spacer size={8} />
      <p>{t("Objects:general_page_description")}</p>

      {!!data.dateOfDeath && (
        <>
          <Spacer size={32} />
          <Alert
            title={`${t(
              "Objects:deceased"
            )} - ${DateTimeHelper.formatDateString(
              data.dateOfDeath.toString()
            )}.`}
            withDefaultIcon
            variant="warning"
          />
        </>
      )}

      <Spacer size={32} />

      {renderAbsence()}

      <h2>{t("Objects:general_basic_details_title")}</h2>
      <Spacer size={8} />
      <p>{t("Objects:general_basic_details_description")}</p>

      <Spacer size={16} />

      <ObjectGeneralForm
        defaultValues={data}
        objectId={objectId}
        onSubmit={onSubmit}
        alarmHandlings={alarmHandlings}
        objectPhoto={thumbnail}
        organizations={organisationTree}
      />
      <div className={styles.container}>
        <ManualSyncWrapper
          clusterData={{
            lastSync: data.lastClusterSync,
            latestSyncAttempt: data.lastClusterSyncAttempt,
          }}
          externalSettingData={{
            syncStatus: data.syncStatus,
            lastSync: data.lastIntegrationSync,
            latestSyncAttempt: data.latestIntegrationSyncAttempt,
          }}
          refetchFunc={fetchObject}
        />
      </div>
    </>
  );
};

export default observer(ObjectGeneralContainer);

import { useEffect, useRef, useState } from "react";
import useTranslations from "../../core/i18n/useTranslations";
import { getUserAccessLog } from "../../core/api/objects/objects";
import { Row, Spacer } from "../Layout/Layout";
import Table from "../../ui-lib/components/Tables/Table";
import { AccessLogItem } from "../../core/api/objects/types";
import DateTimeHelper from "../../core/helpers/dateTimeHelper";
import { SubRow } from "../../ui-lib/components/Tables/SubRow";
import { useHistory } from "react-router";
import { History } from "history";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import Button from "../../ui-lib/components/Button/Button";
import { getErrorKey } from "../Errors/ErrorAlert";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import useUser from "../../core/user/useUser";
import { api as reportApi } from "../../core/api/reports/reports";
import Dropdown from "../../ui-lib/components/Dropdown/Dropdown";
import { usePageFilter } from "../../core/hooks/filters/usePageFilter";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import { notifyApiErrors } from "../../core/helpers/helpers";

type Columns = { [key: string]: string };

// fields with datetime values that should be displayed as date and not datetime, ugly solution but the best i can come up with for now
const dateFields = ["EmergencyCenterValidTo", "DateOfDeath", "DateOfBirth"];
const enumFields = [
  "MaritalStatus",
  "HairColour",
  "EyeColour",
  "Gender",
  "NoteType",
  "Culture",
  "CameraViewingAllowance",
];

const cleanName = (name: string) => {
  if (name.endsWith("V1")) {
    return name.slice(1, name.length - 2);
  }
  return name;
};

// return the link to the entity that was edited in the changelog
const getModifiedEntityPath = (name: string, id: number, userId: string) => {
  if (name === "TTransmitterV1") {
    return `/adminportal/objects/${userId}/accessories?transmitterId=${id}`;
  }

  if (name === "TPeriodicTransmitterDisableV1") {
    return `/adminportal/objects/${userId}/accessories?transmitterId=${id}&periodicDisables=1`;
  }

  if (name === "TCameraV1" || name === "TLocationV1") {
    return `/adminportal/objects/${userId}/accessories`;
  }

  if (name === "TAddressV1") {
    return `/adminportal/objects/${userId}/response?addressId=${id}`;
  }

  if (name === "TUserContactV1") {
    return `/adminportal/objects/${userId}/response?contactId=${id}`;
  }

  if (name === "TContactV1") {
    return `/adminportal/common-contacts/${id}`;
  }

  if (name === "TPeriodicUserInteractionV1") {
    return `/adminportal/objects/${userId}/advanced-settings`;
  }

  if (name === "TExternalCredentialV1") {
    return `/adminportal/objects/${userId}/accessories?transmitterId=${id}`;
  }

  return name;
};

const parseValue = (value: string, type: string, t: any) => {
  if (DateTimeHelper.checkIsDateTimeString(value)) {
    if (dateFields.includes(type)) {
      return DateTimeHelper.formatDateString(value);
    }

    return DateTimeHelper.formatDateTimeString(value);
  }

  if (type === "Color") {
    return (
      <>
        <span>#{value}</span>
        <span
          className="objectChangeLogColorBox"
          style={{ background: "#" + value }}
        ></span>
      </>
    );
  } else if (enumFields.includes(type)) {
    return t(`CommonEnum:${type}${value}`);
  }
  return value;
};

const getTranslation = (
  t: any,
  type: string,
  field: string,
  entity: string
) => {
  if (type === field) {
    return "";
  }

  // specific translations for ScheduleItems as translations for for example Name is very generic and looks bad in the UI
  if (entity === "TScheduleItemV1") {
    if (field === "Name") field = "AbsenceReason";
    if (field === "Comment") field = "AbsenceComment";
    if (field === "EndTime") field = "AbsenceEndDate";
    if (field === "StartTime") field = "AbsenceStartDate";
  }

  return t(`Language:${cleanName(field)}`);
};

export const defaultColumns = (t: (key: string) => string) => [
  {
    key: "type",
    header: t("Objects:accesslog_table_columns_accessType"),
    fieldTemplate: (rowData: AccessLogItem) => {
      return <TableCell value={t("Objects:AccessType" + rowData.accessType)} />;
    },
    style: {
      width: "100px",
    },
  },
  {
    key: "timestamp",
    header: t("Objects:accesslog_table_columns_timestamp"),
    fieldTemplate: (rowData: AccessLogItem) => (
      <TableCell
        value={
          rowData.timestamp
            ? DateTimeHelper.formatDateTime(rowData.timestamp)
            : ""
        }
      />
    ),
    style: {
      width: "170px",
      "max-width": "170px",
    },
  },
  {
    key: "name",
    header: t("Objects:accesslog_table_columns_adminUsername"),
    fieldTemplate: (rowData: AccessLogItem) => {
      return <TableCell value={rowData.adminUsername} />;
    },
    style: {
      width: "200px",
      "max-width": "200px",
    },
  },
];

const columns = (
  t: (key: string) => string,
  objectId: string,
  history: History<unknown>
) => [
  ...defaultColumns(t),
  {
    key: "modified",
    header: t("Objects:accesslog_table_columns_modified"),
    style: { padding: "0" },
    fieldTemplate: (rowData: AccessLogItem) =>
      rowData.changes.map((change, i) => {
        return (
          <SubRow
            key={change.id}
            displayDivider={i + 1 !== rowData.changes.length}
            value={
              <div className="objectChangeLogRow">
                <div className="objectChangeLogField">
                  {getTranslation(t, change.name, change.field, change.name)}
                </div>
                {change.oldValue ? (
                  <div className="objectChangeLogOldValue">
                    {change.oldValue.endsWith("V1")
                      ? getTranslation(
                          t,
                          change.oldValue,
                          cleanName(change.oldValue),
                          change.name
                        )
                      : parseValue(change.oldValue, change.field, t)}
                  </div>
                ) : (
                  <div className="objectChangeLogField">
                    {change.field.endsWith("V1") && change.field !== "TUserV1"
                      ? t("Language:Added")
                      : t("Language:NotSpecified")}
                  </div>
                )}
              </div>
            }
            hierarchyButton={undefined}
            changeLogButton={undefined}
          />
        );
      }),
  },
  {
    key: "changedTo",
    style: { padding: "0" },
    header: "",
    fieldTemplate: (rowData: AccessLogItem) =>
      rowData.changes.map((change, i) => {
        return (
          <SubRow
            key={change.id}
            displayDivider={i + 1 !== rowData.changes.length}
            value={
              <div className="objectChangeLogRow">
                <div className="objectChangeLogField">
                  {getTranslation(t, change.name, change.field, change.name)}
                </div>

                {change.newValue ? (
                  <div className="objectChangeLogNewValue">
                    {change.newValue.endsWith("V1")
                      ? getTranslation(
                          t,
                          change.field,
                          cleanName(change.newValue),
                          change.name
                        )
                      : parseValue(change.newValue, change.field, t)}
                  </div>
                ) : (
                  <div>{t("Language:WasRemoved")}</div>
                )}
              </div>
            }
            hierarchyButton={undefined}
            changeLogButton={
              ![
                "TUserV1",
                "TScheduleItemV1",
                "TScheduleV1",
                "TAlarmCodeRuleV1",
              ].includes(change.name) && change.newValue
                ? (event: any) => {
                    if (event.type === "click") {
                      event.stopPropagation();

                      history.push(
                        getModifiedEntityPath(change.name, change.id, objectId)
                      );
                    }
                  }
                : undefined
            }
          />
        );
      }),
  },
];

const INITIAL_LOG_SETTINGS = {
  read: true,
  write: true,
};

export const ObjectAccessLog = ({ objectId }: { objectId: string }) => {
  const [tableData, setTableData] = useState<AccessLogItem[]>([]);
  const [logFilter, setLogFilter] = useState(INITIAL_LOG_SETTINGS);
  const abortControllerRef = useRef<AbortController | null>(null);

  const history = useHistory();
  const t = useTranslations();
  const { pageFilter, setPageFilter, resetPageFilter } = usePageFilter();

  const { data: accessLog, isLoading } = getUserAccessLog(objectId, {
    ...pageFilter,
    ...logFilter,
  });
  const user = useUser();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isStopDownloadOpen, setIsStopDownloadOpen] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const onDone = () => {
    setIsExportLoading(false);
    setIsExportModalOpen(false);
  };

  const onError = (error: any) => {
    setIsExportLoading(false);

    const errorKey = getErrorKey(error);
    notify({
      message: t(`Errors:${errorKey}`),
      variant: "error",
    });
  };

  const { downloadReport } = reportApi(
    user.authenticatedRequest,
    onDone,
    onError
  );

  useEffect(() => {
    if (accessLog) {
      setTableData(accessLog.rows);
    }
  }, [accessLog]);

  const allColumns = columns(t, objectId, history).reduce<Columns>(
    (prev, current) => ({
      ...prev,
      [current.key]: current.header,
    }),
    {}
  );

  const tryDownloadReport = async () => {
    setIsExportLoading(true);
    abortControllerRef.current = new AbortController();
    try {
      await downloadReport({
        reportId: 54,
        reportArguments: {
          runMode: "BySearchCriteria",
          entityIds: [+objectId],
          organizationIds: [],
          searchText: "",
        },
        signal: abortControllerRef.current.signal,
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const cancelDownload = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsExportLoading(false);
    setIsStopDownloadOpen(false);
    setIsExportModalOpen(false);
  };

  const placeholder = t("Common:all");

  const items = [
    {
      id: "all",
      name: `--${placeholder}--`,
      isSelected: logFilter.read && logFilter.write,
      onSelectItem: () => {
        if (!(logFilter.read && logFilter.write)) {
          setTableData([]);
        }
        setLogFilter(INITIAL_LOG_SETTINGS);
      },
    },
    {
      id: "write",
      name: t("Objects:AccessTypeWrite"),
      isSelected: logFilter.write && !logFilter.read,
      onSelectItem: () => {
        if (!(logFilter.write && !logFilter.read)) {
          setTableData([]);
        }
        setLogFilter({ read: false, write: true });
      },
    },
    {
      id: "read",
      name: t("Objects:AccessTypeRead"),
      isSelected: logFilter.read && !logFilter.write,
      onSelectItem: () => {
        if (!(logFilter.read && !logFilter.write)) {
          setTableData([]);
        }
        setLogFilter({ read: true, write: false });
      },
    },
  ];

  const selectedItem = items.find((i) => i.id !== "all" && i.isSelected);

  return (
    <>
      <PrimeModal
        withHeader
        withFooter
        header={t("Objects:download_accesslog")}
        isOpen={isExportModalOpen}
        onClose={() => {
          if (!isExportLoading) {
            setIsExportModalOpen(false);
          }
        }}
        submitBtn={{
          variant: "primary",
          text: t("Common:download"),
          onClick: tryDownloadReport,
          disabled: isExportLoading,
          loading: isExportLoading,
        }}
        cancelBtn={{
          text: t("Common:cancel"),
          onClick: () => {
            if (!isExportLoading) {
              setIsExportModalOpen(false);
            } else {
              setIsStopDownloadOpen(true);
            }
          },
        }}
        contentStyle={{ width: "848px" }}
      >
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Objects:want_download_accesslogs")}
        </p>
        <Spacer size={16} />
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Objects:want_download_accesslogs_extra_text")}
        </p>
      </PrimeModal>

      <PrimeModal
        withHeader
        withFooter
        header={t("Objects:stop_downloading")}
        isOpen={isStopDownloadOpen}
        onClose={() => setIsStopDownloadOpen(false)}
        submitBtn={{
          variant: "destructive",
          text: t("Common:stop"),
          onClick: cancelDownload,
        }}
        cancelBtn={{
          text: t("Common:keep_downloading"),
          onClick: () => setIsStopDownloadOpen(false),
        }}
        contentStyle={{ width: "848px" }}
      >
        <p style={{ color: "var(--Grey-600)" }}>
          {t("Objects:want_cancel_download")}
        </p>
      </PrimeModal>

      <Row type="space" style={{ width: "100%" }}>
        <h1>{t("Menu:Objects_AccessLog")}</h1>

        {user.config?.show.includes("UserUpdateLogs") && (
          <Button
            variant="secondary"
            image="download"
            text={t("Objects:download_accesslog")}
            onClick={() => setIsExportModalOpen(true)}
          />
        )}
      </Row>
      <Spacer size={32} />

      <Dropdown
        placeholder={t("Common:all")}
        width={156}
        selectedItem={selectedItem}
        items={items}
        onSelectItem={(i) => {
          const current = items.find((item) => item.id === i.id);
          current?.onSelectItem();
          resetPageFilter();
        }}
      />
      <Spacer size={16} />

      {isLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <Table<AccessLogItem>
          tableName="table-objectaccesslog"
          items={tableData}
          isLoading={isLoading}
          columns={columns(t, objectId, history).filter((c) =>
            Object.keys(allColumns).find((key) => c.key === key)
          )}
          hideEmptyMessage
          withPagination
          showRowHover
          withLazyLoading
          paginatedItems={{
            items: tableData,
            pagination: {
              offset: pageFilter.pageSize * (pageFilter.page - 1),
              limit: pageFilter.pageSize,
              total: accessLog?.total,
            },
          }}
          onPageChange={(nextPage) => {
            const page = Math.floor(nextPage.offset / nextPage.limit);

            if (!Number.isNaN(page) && nextPage.limit) {
              setPageFilter({
                page: page + 1,
                pageSize: nextPage.limit,
              });
            }
          }}
        />
      )}
    </>
  );
};

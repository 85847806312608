import { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Row, Spacer } from "../../components/Layout/Layout";
import { Step2Info } from "./Step2Info";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import authApi from "../../core/api/auth";
import Button from "../../ui-lib/components/Button/Button";
import NumberInput from "../../ui-lib/components/Inputs/NumberInput";
import Checkbox from "../../ui-lib/components/Checkbox/Checkbox";
import useTranslations from "../../core/i18n/useTranslations";

import styles from "./Login.module.css";

export interface Step2FormData {
  token: string;
  code: string;
  rememberMe: boolean;
}

export const Step2 = ({
  step1Data,
  onSubmit,
}: {
  step1Data: {
    token: string;
    authType: string;
    username: string;
    password: string;
    phoneNumber?: string;
  };
  onSubmit: (formData: Step2FormData) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const t = useTranslations();

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    setValue,
  } = useForm<Step2FormData>({
    defaultValues: {
      rememberMe: false,
      token: step1Data.token,
    },
  });

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const resend = async () => {
    const data = await authApi.getToken({
      username: step1Data.username,
      password: step1Data.password,
    });

    if (data.access_token) {
      setValue("token", data.access_token);
    }

    notify({ message: t("Messages:security_code_new_sent") });
  };

  return (
    <div className={styles.step2_container}>
      <h1>{t("Common:login_2fa")}</h1>
      <Spacer size={32} />
      <Step2Info
        type={step1Data.authType}
        phoneNumber={step1Data.phoneNumber}
      />
      <Spacer size={32} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="code"
          control={control}
          rules={{ required: true, maxLength: 6, minLength: 6 }}
          render={({ field }) => (
            <NumberInput
              required
              inputRef={inputRef}
              value={field.value}
              onChange={field.onChange}
              placeholder={t("Common:form_security_code_placeholder")}
              validationError={
                errors.code && t("Errors:input_security_code_format")
              }
            />
          )}
        />
        <Spacer size={16} />
        <Controller
          name="rememberMe"
          control={control}
          render={({ field }) => (
            <Row style={{ justifyContent: "flex-start" }}>
              <Checkbox
                checked={field.value}
                onChange={field.onChange}
                style={{ padding: 0 }}
                label={t("Common:login_2fa_remember_me")}
              />
            </Row>
          )}
        />
        <Spacer size={32} />
        <div className={styles.step2_buttons}>
          <Button
            className={styles.setp2_submitBtn}
            type="submit"
            text={t("Common:verify")}
            loading={isSubmitting}
          />
          {step1Data.authType === "sms" && (
            <>
              <Spacer size={32} />
              <p className={classNames(styles.step2_sendInfo, "subTitleSmall")}>
                {t("Common:login_2fa_send_info")}{" "}
                <button
                  className={classNames(styles.setp2_resendBtn)}
                  onClick={resend}
                  aria-label="Resend a new code"
                >
                  {t("Common:login_2fa_resend")}
                </button>
              </p>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

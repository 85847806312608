import { breakpoints, useDimensions } from "../../core/hooks/dimensionProvider";
import { AlignType, ColType, Column, Row, RowType } from "../Layout/Layout";

export const ResponsiveWrapper = ({
  rowAlign,
  rowType,
  children,
  rowStyle,
  colAlign,
  colType,
  colStyle,
  style,
  className,
}: {
  rowAlign?: AlignType;
  rowType?: RowType;
  rowStyle?: React.CSSProperties;
  colAlign?: AlignType;
  colType?: ColType;
  colStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
}) => {
  const { width } = useDimensions();
  return width < breakpoints.desktop ? (
    <Column
      className={className}
      align={colAlign}
      type={colType}
      style={style ?? colStyle}
    >
      {children}
    </Column>
  ) : (
    <Row
      className={className}
      align={rowAlign}
      type={rowType}
      style={style ?? rowStyle}
    >
      {children}
    </Row>
  );
};

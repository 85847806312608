import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { breakpoints, useDimensions } from "../../core/hooks/dimensionProvider";
import { Spacer } from "../../components/Layout/Layout";
import useTranslations from "../../core/i18n/useTranslations";
import TextInput from "../../ui-lib/components/Inputs/TextInput";
import Button from "../../ui-lib/components/Button/Button";
import authApi from "../../core/api/auth";
import Alert from "../../ui-lib/components/Alerts/Alert";

import styles from "../Login/Login.module.css";

export const ForgotPassword = () => {
  const t = useTranslations();
  const history = useHistory();
  const { width } = useDimensions();

  const [userName, setUserName] = useState("");
  const [isError, setIsError] = useState<{
    required?: boolean;
    wrongAdmin?: boolean;
  }>({});

  const onForgotPasswordRequestSubmit = async () => {
    try {
      await authApi.resetPasswordRequest(userName);
      localStorage.removeItem("userResetToken");
      notify({
        message: t("Common:reset_password_success"),
        autoCloseTime: 10000,
      });
      history.push("/adminportal/login");
    } catch (error: any) {
      setIsError({ wrongAdmin: true });
      if (width >= breakpoints.tablet) {
        notify({
          message: t("Errors:response_user_admin_entry_not_found"),
          variant: "error",
        });
      }
    }
  };

  const resetPassword = () => {
    if (!userName) {
      setIsError({ required: true });
      return;
    }
    if (isError.wrongAdmin) return;
    onForgotPasswordRequestSubmit();
  };

  return (
    <>
      <h1 className={styles.forgotPasswordTitle}>
        {t("Common:reset_password_title")}
      </h1>
      <p className={styles.forgotPasswordSubTitle}>
        {t("Common:reset_password_subtitle")}
      </p>
      <TextInput
        dataCy="forgotPasswordUsername"
        className={styles.forgotPasswordUsername}
        label={t("Administrator:general_input_username")}
        onChange={(e) => {
          if (Object.keys(isError).length) {
            setIsError({});
          }
          setUserName(e.target.value);
        }}
        value={userName}
        validationError={
          isError.required ? t("Errors:input_field_required") : ""
        }
        placeholder={t("Common:enter_your_username")}
      />
      <div className={styles.forgotPasswordBtns}>
        <Button
          className={styles.resetPwdBtn}
          text={t("Common:reset_password")}
          onClick={resetPassword}
        />
        {width < breakpoints.desktop && <Spacer size={16} />}
        <Link to="/adminportal/login" className={styles.returnLink}>
          {t("Common:return_to_login")}
        </Link>

        {width < breakpoints.tablet && isError.wrongAdmin && (
          <>
            <Spacer size={40} />
            <Alert
              withDefaultIcon
              variant="error"
              title={t("Errors:response_user_admin_entry_not_found")}
              dataCy="wrongUserNameAlert"
            />
          </>
        )}
      </div>
    </>
  );
};
